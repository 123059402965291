import React, { useState, useRef, useEffect} from "react";
import ReactGA from 'react-ga';
import emailjs from "@emailjs/browser";
import "./BookingClean.css";
import { Helmet } from "react-helmet"
import Header from "../components/Header";
import { BiHomeSmile } from 'react-icons/bi';
import { BsFileEarmarkPerson } from 'react-icons/bs';
import { BiLocationPlus } from 'react-icons/bi';
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import icon from "../assets/iconG.png"
import { IoMdStar } from "react-icons/io";
import TrippleIconsGym from "../components/TrippleIconsGym";





// Define a component called Result to display a confirmation message

const Result = () => {
  return (
    <p className="result-msg">Our team will contact you shortly</p>
  );
};




<p className="price book-later">Send booking request and our team will contact you shortly to confirm<br/> booking.</p>
// Define the BookingClean component
const BookingGym = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Google Tag for remarketing and conversion tracking
    window.gtag('config', 'AW-11025934865');
  }, []);

  useEffect(() => {
  ReactGA.initialize('G-ECHQXBFYLM'); 
  // Report page view
  ReactGA.pageview(window.location.pathname + window.location.search);
}, []);


useEffect(() => {
   console.log(window.location.pathname)
  })
  


  useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []);



  const FormSubmission = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Form submission button clicked'
    });
  };

  // Define state variables for form inputs and result display

  const [result, showResult] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState("");
  const [selectedBedroom, setSelectedBedroom] = useState("1");
  const [selectedBathroom, setSelectedBathroom] = useState("1");
  const [selectedKitchen, setSelectedKitchen] = useState("1");
  const [selectedCleanliness, setSelectedCleanliness] = useState("standard_clean");

  
  

  const form = useRef(null);

   // Function to send an email and display a confirmation message

  const sendEmail = (e) => {
    e.preventDefault();

  

        // Include the selected extras in the email template
      

    
    emailjs
      .sendForm("service_k4aiwmr", "template_yijzv0k", form.current, "Fh0Y9YM-DloiKhGif",)
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    showResult(true);

    navigate("/Thanks");
  };


  // Render the BookingClean component

  return (
    <div>
    <Helmet>
          <meta name="description" content="We offer general and end of lease cleaning service."/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11025934865"></script>
        </Helmet>
      <Header/>
      <div className="icon-wrap-proccess">
        <TrippleIconsGym/>
      </div>
      
      <div className="form-clean-book">
        
        <h2 className="clean-heading">Book a walk through</h2>
        <h2 className="clean-h2 text-inperson">We will visit onsite and<br/> build a custom quote.</h2>

        <form className="form-clean" ref={form} onSubmit={sendEmail}>
        <div className="flex-book-container">
          <div className="form-one">
          <p className="price_info"></p>
            <div className="form-container-clean">
            <div className="icon-heading-container">
                    <BsFileEarmarkPerson size={25} />
                    <h4 className="clean-h4">WHO YOU ARE</h4>
                  </div>
           
              <p className="clean-p">This information will be used to contact<br /> you about your service.</p>

              <input className="input-contact-clean" type="text" name="user_name" placeholder="Name" required />
              <input className="input-contact-clean" type="email" name="user_email" placeholder="Email" required />
              <input className="input-contact-clean" type="text" name="user_mobile" placeholder="Mobile" required />

              <div className="address-section">
              <div className="icon-heading-container">
                    <BiLocationPlus size={25} />
                    <h4 className="clean-h4">LOCATION</h4>
                  </div>
             
                <p className="clean-p">We will look at your facility online prior, <br/>to provide an estimate.</p>
                <textarea className="input-contact-clean" type="text" name="address" placeholder="Address" required />
                <textarea className="input-contact-clean" type="text" name="suburb" placeholder="Suburb" required />
              </div>

                <div className="icon-heading-container">
                    <BiHomeSmile size={25} />
                    <h4 className="clean-h4">TELL US WHAT SERVICE YOU NEED</h4>
                  </div>
              <div className="tell-us-service">
                <select className="input-contact-clean input-select-width" name="bedroom" onChange={(e) => setSelectedBedroom(e.target.value)} required>
                  <option value="Small health club" >Body corporate cleaning</option>
                  <option value="Medium-large health club">Health club cleaning</option>
                  <option value="Leisure centre">Commercial steam clean</option>
                  <option value="Small studio">Office cleaning</option>
                 
                </select>

                <select className="input-contact-clean input-select-width" name="bathroom" onChange={(e) => setSelectedBathroom(e.target.value)} required>
                
                  <option value="Cleaning strorage available">Cleaning strorage available</option>
                  <option value="Available onsite">Available onsite</option>
                  <option value="Not available">Not available</option>
               
                </select>

                <select className="input-contact-clean input-select-width" name="kitchen" onChange={(e) => setSelectedKitchen(e.target.value)} required>
                 
                  <option value="1">Do you need us to supply cleaning products?</option>
                  <option value="We will supply cleaing products">We will supply cleaing products</option>
                  <option value="I need cleaing products supplied">I need cleaing products supplied</option>
                </select>

                <h6 className="extra-icons">Once off heavy deep clean needed?</h6>
                <h8 className="rec-deep">(Recommended for first time clean to <br/>reset to general cleaning condition)</h8>
                <select className="input-contact-clean input-select-width" name="cleanliness" onChange={(e) => setSelectedCleanliness(e.target.value)} required>
                  
                  <option value="standard_clean">No just general maintenance clean needed</option>
                  <option value="medium_deep_clean">Needs a once off medium deep clean</option>
                  <option value="heavy_deep_clean">Needs a once off heavy deep clean</option>
                </select>
              </div>

              <div className="book-clean">
                <h4 className="clean-h4">WHEN WOULD YOU LIKE AN ONSITE INSPECTION?</h4>
                <p className="clean-p">Choose the date and arrival window*<br /> that works for you. If you need a <br />last-minute appointment give<br /> us a call at 0433310964</p>
                <input
                  className="input-contact-clean"
                  type="datetime-local"
                  name="clean_date"
                  onChange={(e) => setSelectedDateTime(e.target.value)}
                  required
                />
              </div>
              <h4 className="clean-h4">Comments & Special Instructions</h4>
              <textarea className="input-contact-clean" type="text" name="Instructions" placeholder="Is there anything we should know? "  />
              
            </div>
          </div>
          
    
     

      </div>  
          <div> {result ? <Result /> : null}</div>
          
          <input onClick={FormSubmission} className="qoute-button" type="submit" value="Send booking request" />
          

           <div className="review-bottom">
            <div className="review-content-bottom">
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <img className="gimage" src={icon} alt="logo for customer rating"/>
           </div>

           </div>
        </form>
        <Footer/>
      </div>  
    </div>
  );
};

export default BookingGym;



