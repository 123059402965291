import React from 'react'
import carpetRoom from "../assets/Oven.png"
import "./SteamCleanTop.css";
import { Link } from 'react-router-dom';


const EndLeaseThree = () => {
  return (
    
    <div className='carpet-wrapper carpet-wrapper-two'>
        
        <div className='carpet-description third-end-wrap'>
         <h2 className='not-sure rec-head'>Reliable Move Out Cleaning Services in Melbourne</h2>
         <h4 className='not-sure rec-head'>Reliable end of lease cleaning near me</h4>
            <p className='p-description'>Are you dreading the hassle of cleaning your apartment before moving out? Sprucely Clean is here to help, we have end of lease cleaners available near you now. We provide move out apartment cleaning services to take the burden off your shoulders and ensure a stress-free transition. Imagine handing the keys to your landlord with absolute confidence, knowing that every inch of your living space has been perfectly cleaned.</p>
            <p className='p-description'>With our move out apartment cleaning services, you get a bond-back guarantee, ensuring you receive your deposit back without any hassles. We offer a 72-hour guarantee, which means that if your landlord is not satisfied with the cleaning results, our cleaning team will return to re-clean the missed areas free of charge, ensuring complete deposit money back.</p>
            <p className='p-description'>Our end of lease cleaning Melbourne service is not limited by time, ensuring that our cleaning team stays as long as necessary to ensure your property manager or landlord's satisfaction.</p>
            <p className='p-description'>Booking our exit clean Melbourne service is fast and convenient, as we offer same-day service, morning and afternoon appointments 7 days a week, and no extra charges on bank holidays and weekends.</p>
            <p className='p-description'>We provide a custom-made cleaning checklist for your needs. And for your convenience, we can work with the checklist landlords offer when the tenants are moving out. Either way, you will get a thorough cleaning of the whole vacated property.</p>
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/end-of-lease-cleaning"><button className='p-description end-lease-link end-three'>LIVE BOOKING AND PRICE</button></Link>
       
           
        
        </div>
        <div className='image-carpet oven-three'>
            <img className="carpet-room" src={carpetRoom} alt="Carpet that has been steamed clean"/>
        </div> 
    </div>
  )
}

export default EndLeaseThree