import React, { useEffect, useRef, useState } from 'react';
import { RiStarSFill } from 'react-icons/ri';
import arone from '../assets/arone.png';
import artwo from '../assets/artwo.png';
import arthree from '../assets/arthree.png';
import arfour from '../assets/arfour.png';
import arfive from '../assets/arfive.png';
import arsix from '../assets/arsix.png';
import arseven from '../assets/arseven.png';
import areight from '../assets/areight.png';
import arnine from '../assets/arnine.png';
import './ReviewSection.css';
import { Link } from 'react-router-dom';

const ReviewSection = () => {
  const [bookingsCount, setBookingsCount] = useState(0);
  const [customerSatisfaction, setCustomerSatisfaction] = useState(0);
  const bookingsRef = useRef(null);
  const satisfactionRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust this threshold as needed
    };

    const bookingsObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Start counting from 0 to 190 for bookings
          const interval = setInterval(() => {
            setBookingsCount((prevCount) =>
              prevCount === 190 ? prevCount : prevCount + 1
            );
          }, 20); // Adjust the interval as needed

          // Stop counting when reaching the target value
          if (bookingsCount >= 190) {
            clearInterval(interval);
          }
        }
      });
    }, options);

    const satisfactionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Start counting from 0 to 100 for customer satisfaction
          const interval = setInterval(() => {
            setCustomerSatisfaction((prevSatisfaction) =>
              prevSatisfaction === 100 ? prevSatisfaction : prevSatisfaction + 1
            );
          }, 20); // Adjust the interval as needed

          // Stop counting when reaching the target value
          if (customerSatisfaction >= 100) {
            clearInterval(interval);
          }
        }
      });
    }, options);

    if (bookingsRef.current) {
      bookingsObserver.observe(bookingsRef.current);
    }

    if (satisfactionRef.current) {
      satisfactionObserver.observe(satisfactionRef.current);
    }

    return () => {
      if (bookingsRef.current) {
        bookingsObserver.unobserve(bookingsRef.current);
      }
      if (satisfactionRef.current) {
        satisfactionObserver.unobserve(satisfactionRef.current);
      }
    };
  }, [bookingsCount, customerSatisfaction]);

  return (
    <div className="review-grid-container">
      <div className="review-boxes">
        <div className="review-box-one">
          <p className="review-number review-bookings" ref={bookingsRef}>
            {bookingsCount}
          </p>
          <h4 className="review-box-heading">Bookings in July</h4>
        </div>

        <div className="review-box-one">
          <p className="review-number">0</p>
          <h4 className="review-box-heading">Reviews less than 5.0</h4>
        </div>
        <div className="review-box-one">
          <p className="review-number customer-rateing" ref={satisfactionRef}>
            {customerSatisfaction}%
          </p>
          <h4 className="review-box-heading">Customer satisfaction</h4>
        </div>
      </div>
      <h3 className="review-heading">Customer Reviews</h3>
        <div className="stars-reviews">
        <RiStarSFill size={50} color="gold" />
        <RiStarSFill size={50} color="gold" />
        <RiStarSFill size={50} color="gold" />
        <RiStarSFill size={50} color="gold" />
        <RiStarSFill size={50} color="gold" />
        </div>
    <div className="image-review-container">
        <img className="review-img" src={arone} alt="Customer review"/>
        <img className="review-img" src={artwo} alt="Customer review"/>
        <img className="review-img" src={arthree} alt="Customer review"/>
        <img className="review-img" src={arfour} alt="Customer review"/>
        <img className="review-img" src={arfive} alt="Customer review"/>
        <img className="review-img" src={arsix} alt="Customer review"/>
        <img className="review-img" src={arseven} alt="Customer review"/>
        <img className="review-img" src={areight} alt="Customer review"/>
        <img className="review-img" src={arnine} alt="Customer review"/>
   </div> 
   
   <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/GiveGoogleReview"><button className='review-button'>Give us a review</button></Link>
    </div>
  );
};

export default ReviewSection;



