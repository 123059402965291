import React from 'react';
import './BlogSection.css';
import blogImage from "../assets/grid2.png"
import blogImage2 from "../assets/lawn.png"
import CleanImage from "../assets/clean1.png"
import { Link } from 'react-router-dom';

const BlogSection = () => {

  

  
  return (
    <div className="main-blog-wrapper">
    <div className="blog-section-container">
    <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/BlogBond">
      <div className="blog-container">
      <img className="blog-image" src={CleanImage} alt="logo"/>
      <div className="blog-p">
        <h2>Guide to getting your bond back</h2>
        <p className="p-color">
          Moving out of a rental property can be a stressful experience, especially when it comes to getting your bond back. A thorough end of lease clean is crucial to ensure that you receive your full bond amount. In this blog post, we will provide a comprehensive guide on how to get your bond back with an end of lease clean in Australia.
        </p>
      </div>  
      </div>
      </Link>
    <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/BlogReno">
      <div className="blog-container">
      <img className="blog-image" src={blogImage} alt="logo"/>
      <div className="blog-p">
        <h2>Go organic with your cleaning</h2>
        <p className="p-color">
          Keeping your kitchen clean and hygienic is essential for maintaining good health, but that doesn't mean you have to use harsh chemicals to do it. There are plenty of natural cleaning solutions you can use to clean your kitchen without relying on chemicals. This could also save you in the long run avoiding the need to buy expensive branded products.
        </p>
      </div>  
      </div>
      </Link>
      <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/BlogCost">
      <div className="blog-container">
      <img className="blog-image" src={blogImage2} alt="logo"/>
      <div className="blog-p">
        <h2>Lawn in winter</h2>
        <p className="p-color">
          Looking after your lawn during winter is crucial to maintaining its health and appearance. Here are some tips to ensure your lawn stays in top condition: Firstly, avoid walking on your lawn during frosty weather to prevent damage. Secondly, mow your lawn to its shortest length before the first frost arrives. Finally, remove any debris and leaves to allow the grass to breathe. By following these simple steps, you can ensure your lawn is well-kept and healthy throughout the winter months.
        </p>
        </div>
      </div>
      </Link>
      
    </div>
  </div>  
  );
};

export default BlogSection;
