import React, { useEffect } from 'react';
import './Location.css';

const Location = ({ locations }) => {
  useEffect(() => {
    // Load the Google Maps JavaScript API script
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDvNYdzi4K-rsCeymV7t4FLwLkxoohMxhU&callback=initMap`;
    script.defer = true;
    script.async = true;
    window.initMap = initMap;
    document.head.appendChild(script);

    // Initialize the map
    function initMap() {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: -37.8136, lng: 144.9631 }, // Center of Melbourne
    zoom: 10 // Zoom level to show the entire city
      });

      // Add markers for each location
      locations.forEach(location => {
        new window.google.maps.Marker({
          position: { lat: location.lat, lng: location.lng },
          map: map,
          title: location.title || 'Location'
        });
      });
    }

    // Clean up function to remove the script
    return () => {
      document.head.removeChild(script);
    };
  }, [locations]);

  return (
    <div className='containter-map' >
      <div className='map-wrap' id="map" style={{ width: '100%', height: '500px' }}></div>
      <div className='p-map-wrap'>
      <h2>End of lease cleaning near me</h2>
      <p className='end-leas-map-grey'>When it's time to move out of your rental property in Melbourne, ensuring it's clean and tidy is crucial to avoid any bond-related issues for your bond clean. Entrust this task to Sprucely Clean, where our professional end of lease cleaning service follows an agency-approved checklist, covering all essential areas.</p>
      
      <p className='end-leas-map-grey'>We offer end of lease cleaning across Abbotsford
Ascot Vale,
Avondale Heights,
Bellfield,
Briar Hill,
Brunswick,
Bundoora,
Carlton,
Carlton North,
Clifton Hill,
Coburg,
Coburg North,
Doncaster,
Eaglemont,
East Melbourne,
Epping,
Essendon,
Fairfield,
Fawkner,
Fitzroy,
Flemington,
Footscray,
Gladstone Park,
Glenroy,
Gowanbrae,
Greensborough,
Greenvale,
Hadfield,
Hawthorn East,
Heidelberg,
Heidelberg Heights,
Heidelberg West,
Hillside,
Ivanhoe,
Ivanhoe East,
Keilor,
Kensington,
Kingsbury,
Lalor,
Maidstone,
Maribyrnong,
Melbourne Airport,
Montmorency,
Moonee Ponds,
Niddrie,
North Melbourne,
Northcote,
Oak Park,
Parkville,
Pascoe Vale,
Port Melbourne,
Prahran,
Preston,
Research,
Reservoir,
Richmond,
South Wharf,
South Yarra,
Southbank,
Spotswood,
Strathmore,
Strathmore Heights,
Surrey Hills,
Thomastown,
Thornbury,
Toorak,
Viewbank,
West Melbourne,
Williamstown,
Williamstown North,
Windsor,
Yarraville,
.</p>

      <p className='end-leas-map-grey'>Our cleaners meticulously vacuum and mop floors in living areas and bedrooms, wipe skirting boards, and clean built-in cabinets and wardrobes. Windows receive thorough attention, with frames, sills, and tracks wiped clean and cobwebs removed. Dusting and wiping extend to light fittings, doors, door frames, and blinds.</p>

      <p className='end-leas-map-grey'>In the kitchen, we clean cupboards inside and out, polish all surfaces and benches, and deeply clean ovens inside and out. Stovetops, range hoods, and splashbacks also receive attention, and floors are vacuumed and mopped.</p>

      <p className='end-leas-map-grey'>Bathrooms and toilets undergo a comprehensive cleaning, including inside and out of cupboards, polishing mirrors, cleaning toilets, sinks, vanities, and surfaces. Shower areas are descaled, encompassing screens, tiles, walls, and floors, and exhaust fans and vents are wiped dust-free.</p>

      <p className='end-leas-map-grey'>At Sprucely Clean, we prioritize your bond clean ensuring you get your bond back hassle-free. Our Melbourne bond cleaning service ensures your property is left spotless and ready for the next tenants. For stress-free end-of-lease cleaning in Melbourne, contact us today for a complimentary quote.</p>
      </div>
    </div>
  );
}

export default Location;

