import React, { useEffect } from 'react'
import "./BlogPages.css"
import { Helmet } from "react-helmet"
import { AiOutlineMinus } from 'react-icons/ai';
import Header from '../components/Header'
import Footer from '../components/Footer'
import JumpingButton from '../components/JumpingButton'
import ReactGA from "react-ga";


const BlogCost = () => {

   useEffect(() => {
    // Google Tag for remarketing and conversion tracking
        window.gtag('config', 'AW-11025934865');
      }, []);


  
    useEffect(() => {
      ReactGA.initialize('G-ECHQXBFYLM'); 
      // Report page view
      ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    useEffect(() => {
      console.log(window.location.pathname)
      })


  return (
    <div className="blog-main-wrapper">
    <Helmet>
          <meta name="description" content="We offer general and end of lease cleaning service."/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11025934865"></script>
        </Helmet>
    <Header/>
    <div className="blog-container-pages">
    <div className="heading-content heading-overlay-blog">
    <div className="heading-overlay">
      <h1 className="heading">Australia best <br />home <span className="color__change"><br/>experts.</span><br/><AiOutlineMinus /></h1>
      <div className="jump-container">
        <JumpingButton/>
      </div>
    </div>  
    </div>


    <div className="flex-blog-section">
      <h2 className="blog-pages-heading">Lawn upkeep: <span className="blog-pages-heading-change">Looking after your lawn in winter</span></h2>
      <h3 className="blog-pages-h3"><span className="color-change-steps">Tip 1:</span> Mow your lawn </h3>
      <p className="blog-pages-p">Mow Your Lawn: Continue to mow your lawn as needed during the winter months, its important to keep on-top of cutting your lawn on regular basis. However, raise the height of your mower blades so that the grass is not cut too short.</p>

      <h3 className="blog-pages-h3"><span className="color-change-steps">Tip 2:</span> Aerate Your Lawn</h3>
      <p className="blog-pages-p">Aerate Your Lawn: Aerating your lawn during the winter months will help to promote healthy root growth ready for next spring. Use a garden fork or aerator to create small holes in the soil, allowing air, water, and nutrients to reach the roots.</p>

      <h3 className="blog-pages-h3"><span className="color-change-steps">Tip 3:</span> Fertilize Your Lawn</h3>
      <p className="blog-pages-p">Fertilize Your Lawn: Apply a slow-release fertilizer to your lawn in the late Autum or early winter. This will provide your grass with the nutrients it needs to survive the winter and grow strong in the spring.</p>

      <h3 className="blog-pages-h3"><span className="color-change-steps">Tip 4:</span> Water Your Lawn:</h3>
      <p className="blog-pages-p">Water Your Lawn: Even though you might not need to water your lawn as frequently in the winter due to more frequent rain fall, it is still vital to maintain its moisture levels. Once every week, deeply water your grass to promote root development.
        </p>
      <h3 className="blog-pages-h3"><span className="color-change-steps">Tip 5:</span> When it snows</h3>
      <p className="blog-pages-p">Use snow stakes or other obstacles to prevent your grass from harm if your location often receives heavy snowfall. By doing this, snow will not be forced onto your lawn, where it may suffocate and destroy the vegetation.</p>

      
    </div>
    </div>
    <Footer/>
   </div> 
  )
}

export default BlogCost