import React, { useEffect } from 'react';
import "./TikTok.css";

const Tiktok = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.tiktok.com/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="tiktok-section">
      <h1 className="heading-tik">Follow us on <span className='tik-sp'>socials</span> for cleaning <br/>tips and hacks</h1>
      <div className="tiktok-container">
        <blockquote 
          className="tiktok-embed" 
          cite="https://www.tiktok.com/@shaun.sprucely2/video/7382528885184843024" 
          data-video-id="7382528885184843024"
        >
          <section>
            <a href="https://www.tiktok.com/@shaun.sprucely2/video/7382528885184843024">@shaun.sprucely2</a>
          </section>
        </blockquote>

        <blockquote 
          className="tiktok-embed" 
          cite="https://www.tiktok.com/@shaun.sprucely2/video/7382817982201875729" 
          data-video-id="7382817982201875729"
        >
          <section>
            <a href="https://www.tiktok.com/@shaun.sprucely2/video/7382817982201875729">@anotheruser</a>
          </section>
        </blockquote>

        <blockquote 
          className="tiktok-embed" 
          cite="https://www.tiktok.com/@shaun.sprucely2/video/7402443986142498064" 
          data-video-id="7402443986142498064"
        >
          <section>
            <a href="https://www.tiktok.com/@shaun.sprucely2/video/7402443986142498064">@yetanotheruser</a>
          </section>
        </blockquote>
      </div>
    </div>
  )
}

export default Tiktok;


