import React, {  useEffect } from "react";
import Header from '../components/Header'
import PriceCard from '../components/PriceCard'
import "./BookingPage.css"
import { Helmet } from "react-helmet"
import Footer from '../components/Footer'

const BookingPage = () => {

    useEffect(() => {
    // Google Tag for remarketing and conversion tracking
    window.gtag('config', 'AW-11025934865');
  }, []);
  
  return (
    <div className='booking-page-container'>
    <Helmet>
          <meta name="description" content="We offer general, airbnb turnovers and end of lease cleaning service."/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11025934865"></script>
        </Helmet>

    <Header/>

    <div className="booking-page-content">
    <p className="p-price">Select what clean you need</p>
    <PriceCard/>
    </div>
    <Footer/>

    </div>
  )
}

export default BookingPage