import React from 'react'
import carpetRoom from "../assets/ComAreaOffice.png"
import rating from "../assets/ratingPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css"
import { Link } from 'react-router-dom';

const ComTop = () => {
  return (
    <div className='carpet-wrapper'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Nice looking styled room with carpet"/>
        </div> 
        <div className='carpet-description'>
         <h6>Fill in your details below for an instant quote, our quotes are fixed price with no hidden additional hourly rates.
         We offer commercial cleaning services across Melbourne's northern suburbs and CBD.. </h6>
         
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/><Link style={{ color: '#3AAFA9', }}  to="/Commercial-ceaning-gyms">Commercial gym cleaning service</Link></p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Commercial body corp cleaning service</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Commercial office cleaning</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Commercial steam cleaning</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>24hr support</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>highly trained staff</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>We take care of the operational headache</p>
            <p className='p-description '><AiFillCheckCircle size={30} color='#3AAFA9'/>Flexible contracts</p>
            
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/book-a-walk-through"><button className='p-description end-lease-link mar-p-gym'>GET A QUOTE</button></Link>
            
        <img className="rating-img" src={rating} alt="rating symbol"/>
        </div>
        
    </div>
  )
}

export default ComTop