import React from 'react';
import imageDry from '../assets/clean9.png'; // Import image1
import imageProTec from '../assets/grid1.png'; // Import image2
import imageStain from '../assets/grid6.png'; // Import image1

import './serviceInfoSection.css';

const ServiceInfoSectionEnd = () => {
  const images = [
    { src: imageDry, alt: 'Bathroom checklist', title: 'End of lease Bathroom checklist', description: ['Clean shower & screen','Clean all cupboards inside and out', 'Clean toilets', 'Sink','vanity', 'bowl', 'bathtub and all surfaces', 'fittings'] },
    { src: imageProTec, alt: 'Kitchen checklist', title: 'End of lease Kitchen checklist', description: ['Clean all cupboards inside and out', 'Benches', 'Deep clean oven', 'Rangehood', 'Stovetop', 'Splashback', 'Vacuum plus mop floor', 'fittings'] },
    { src: imageStain, alt: 'Living and Bedroom checklist', title: 'General, Living and Bedroom checklist', description: ['Dust blinds','Vacuum and mop floors', 'Clean cabinets', 'Spot clean walls', 'Clean doors, fittings, cobwebs', 'Dust blinds', 'Clean internal windows plus frames'] },
    // Add more images here
  ];

  return (
    <div> 
      <h2 className='checklist-heading'>Melbourne End Of Lease Cleaning Checklist</h2>
      <p className='p-description p-end'>
          "Securing your bond refund hinges on a thorough cleaning of specific areas within your property. 
          Our bond cleaning service meticulously tackles these areas to ensure you meet the stringent 
          requirements set by landlords and property managers. From deep-cleaning carpets to ensuring kitchen 
          surface areas are spotless, we leave no stone unturned in our quest to restore your property to its original 
          condition. We pay special attention to high-traffic areas, ensuring that floors are pristine and walls are 
          free from marks. Additionally, we meticulously clean bathrooms, including tiles, grout, and fixtures, to ensure 
          they sparkle. By focusing on these key areas, our expert team maximizes your chances of receiving your bond back in full. 
          Trust us to deliver a meticulous bond cleaning service tailored to meet the specific requirements for bond return."</p>
      <div className="service-info-section service-end-wrap">
        {images.map((image, index) => (
          <div key={index} className="service-item">
            <img src={image.src} alt={image.alt} />
            <h3 className='list-items'>{image.title}</h3>
            <div className="checklist-items">
              {image.description.map((item, itemIndex) => (
                <div key={itemIndex} className="checklist-item">
                  
                  <span className='list-items'><span className='list-items' style={{ color: 'green', marginRight: '5px' }}>✓</span>{item}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceInfoSectionEnd;






