import React from 'react';
import './Profile.css';
import ProfileImage from "../assets/profileImage.png"
import { HiOutlineMinus } from 'react-icons/hi';


const ProfileCom = () => {
  return (
    <div className="profile">

     <div className="profile-info-one">
        <img className="profile-photo" src={ProfileImage} alt="profile of director"/>
       </div>
            
        <div className="profile-info-two">
            <p className="description">
            <h5 className="director-name">Shaun Van</h5>
            <h5 className="director-name-profile">Head of Business Development & Client Relations<br/><HiOutlineMinus size={30}/></h5>
            
                <span className="director-p">Over the past 15 years, I have personally worked within commercial environments and understand the importance of how a clean environment can affect the publics perception with creating a welcoming space. So, it’s safe to say I understand the importance of how your club presents to members 
                and how this can impact your brand integrity. My mission is to create a safe, hygienic environment for members and help 
                club owners create a 5-star experience for their members. </span>

            </p>
        </div>
    </div>
  );
};

export default ProfileCom;
