import React from 'react';
import imageDry from '../assets/imageDry.png'; // Import image1
import imageProTec from '../assets/imageProTec.png'; // Import image2
import imageStain from '../assets/imageStain.png'; // Import image1
import imageFlea from '../assets/imageFlea.png'; // Import image2
import imageDustMite from '../assets/carpetLease.png'; // Import image1
import imagePetOd from '../assets/imagePetOd.png'; // Import image2

import './serviceInfoSection.css';

const ServiceInfoSection = () => {
  const images = [
    { src: imageDry, alt: 'Carpet dry cleaning', title: 'Carpet steam cleaning service', description: 'Maximize the lifespan of your carpets with our professional Carpet Steam Cleaning service. Our expert steam cleaning process rejuvenates and revitalizes carpets, removing dirt, stains, and allergens effectively. Trust our team to restore your carpets to their pristine condition, ensuring a fresh and healthy environment for your home or business.' },
    { src: imageProTec, alt: 'Carpet stains', title: 'Added protection against spills and stains', description: 'we offer a protective solution to shield your carpet against spills and stains post-cleaning. This safeguarding layer can be applied after the cleaning procedure. Feel free to inquire for more details!' },
    { src: imageStain, alt: 'Carpet staining', title: 'Carpet steam clean stain removal', description: 'A stain on the carpet is like a stain on the mind, you probably won’t get over it unless it’s gone. Sprucely Clean has got what it takes to make those stains disappear like they never happened. With the use of non-toxic chemicals and the skilful persistence of our team members, the stains won’t see the light of another day.' },
    { src: imageFlea, alt: 'Fleas', title: 'Carpet flea treatment', description: 'Fleas, although practically invisible, can be a noticeable nuisance to both humans and pets alike. Our expertise lies in effectively exterminating these pests using all-natural solutions that are safe for contact with children and pets.' },
    { src: imageDustMite, alt: 'Dust mite', title: 'End of lease steam clean', description: 'Unlock your bond return effortlessly with our specialized End of Lease Steam Clean service. Our thorough carpet steam cleaning guarantees a spotless space, exceeding industry standards and ensuring your property is in prime condition for inspection. Trust us for a seamless transition and maximize your bond return today.' },
    { src: imagePetOd, alt: 'Dog on carpet with pet stain', title: 'Carpet pet stain and odour removal', description: 'Like an invisible threat, dust mites silently contribute to allergies and bacterial growth, particularly within carpet fibers. Their presence poses risks to both children and pets. At Sprucely Clean, were committed to eliminating these pests, ensuring the safety of your family. Get in touch with us today. All carpet steam cleaning services receive Free deodorise and disinfect of carpets.' },
    // Add more images here
  ];

  return (
    <div className="service-info-section">
      {images.map((image, index) => (
        <div key={index} className="service-item">
          <img src={image.src} alt={image.alt} />
          <h3>{image.title}</h3>
          <p>{image.description}</p>
        </div>
      ))}
    </div>
  );
};

export default ServiceInfoSection;
