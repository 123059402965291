import React from 'react'
import arone from '../assets/Reviews/gymOne.png';
import artwo from '../assets/Reviews/gymTwo.png';
import arthree from '../assets/Reviews/gymThree.png';

import './AirBnbRev.css';


const GymRev = () => {
 

  return (
    <div className="review-grid-container">
    
    <div className="image-review-container">
        <img className="review-img" src={arone} alt="Customer review"/>
        <img className="review-img" src={artwo} alt="Customer review"/>
        <img className="review-img" src={arthree} alt="Customer review"/>
      
   </div> 
   
   
    </div>
  );
};

export default GymRev;