import React from 'react';
import './JumpingButton.css';
import { AiFillPhone } from "react-icons/ai";

const EndLeaseButton = () => {
  const phoneNumber = '0433310964'; // Replace this with the desired phone number

  const handleCallClick = () => {
    // Call the function to track the phone call conversion
    gtag_report_conversion();
    // Open the phone dialer with the provided phone number
    window.open(`tel:${phoneNumber}`);
  };

   // Define the Google Ads conversion tracking function
  const gtag_report_conversion = () => {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-11025934865/CvyMCNXEjJkZEJHUyYkp'
    });
  };

  return (
    <div className='button-wrapper'>
      <button className="jumping-button jumping-button-width lease-button" id="myButton" onClick={handleCallClick}>
        Call Now<AiFillPhone className='icon-contact' size={20}/>
      </button>
    </div>
  );
};

export default EndLeaseButton;