
import "./ThankYouPage.css"
import HeaderSteam from '../components/HeaderSteam'
import { Helmet } from "react-helmet"
import React, { useEffect } from 'react';


const ThankYouPage = () => {


useEffect(() => {
  console.log('useEffect is running');
    // Google Ads tracking code
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    
    

    gtag('js', new Date());
    gtag('config', 'AW-11025934865'); // Replace with your actual Google Ads conversion ID

    // GA4 tracking code
    gtag('config', 'G-ECHQXBFYLM'); // Replace with your actual GA4 measurement ID


    

    // Custom tracking logic for the ThankYouPage
    window.dataLayer.push({
      event: 'thankYouConversion',
      // You can include additional data specific to the conversion
      product: 'Booking Confirmation',
      value: 1, // Set an appropriate value for the conversion
    });

    // Additional tracking code specific to this page (if needed)
  }, []);

 

  


  return (
    <div className='thank-wrapper'>
      <Helmet>
        <title>Thank You Page</title>
      </Helmet>
      <HeaderSteam/>
     <div className='thankyou-content'>
     <h3 className='thanks-heading'>THANK YOU</h3>
     <p className='thanks-p'>Your booking is confirmed, our team will be in touch to confirm further details<br/><span className='check-email'>"Please check your email"</span></p>
   </div>
   </div>

  )
}

export default ThankYouPage