import React from 'react'
import arone from '../assets/airone.png';
import artwo from '../assets/airtwo.png';
import arthree from '../assets/airthree.png';
import arfour from '../assets/airfour.png';
import arfive from '../assets/airfive.png';
import arsix from '../assets/airsix.png';
import arseven from '../assets/airseven.png';
import areight from '../assets/aireight.png';
import arnine from '../assets/airnine.png';
import './AirBnbRev.css';


const AirBnbRev = () => {
 

  return (
    <div className="review-grid-container">
    
    <div className="image-review-container">
        <img className="review-img" src={arone} alt="Customer review"/>
        <img className="review-img" src={artwo} alt="Customer review"/>
        <img className="review-img" src={arthree} alt="Customer review"/>
        <img className="review-img" src={arfour} alt="Customer review"/>
        <img className="review-img" src={arfive} alt="Customer review"/>
        <img className="review-img" src={arsix} alt="Customer review"/>
        <img className="review-img" src={arseven} alt="Customer review"/>
        <img className="review-img" src={areight} alt="Customer review"/>
        <img className="review-img" src={arnine} alt="Customer review"/>
        
   </div> 
   
   
    </div>
  );
};

export default AirBnbRev;