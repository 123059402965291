import React, { useEffect } from 'react'
import "./BlogPages.css"
import { AiOutlineMinus } from 'react-icons/ai';
import Header from '../components/Header'
import Footer from '../components/Footer'
import JumpingButton from '../components/JumpingButton'
import ReactGA from "react-ga";


const BlogCost = () => {
  
  
  useEffect(() => {
  ReactGA.initialize('G-ECHQXBFYLM'); 
  // Report page view
  ReactGA.pageview(window.location.pathname + window.location.search);
}, []);
useEffect(() => {
   console.log(window.location.pathname)
  })


  return (
    <div className="blog-main-wrapper">
    <Header/>
    <div className="blog-container-pages">
    <div className="heading-content heading-overlay-blog">
    <div className="heading-overlay">
      <h1 className="heading">Australia best <br />home <span className="color__change"><br/>experts.</span><br/><AiOutlineMinus /></h1>
      <div className="jump-container">
        <JumpingButton/>
      </div>
    </div>  
    </div>


    <div className="flex-blog-section">
      <h2 className="blog-pages-heading">End of lease clean: <span className="blog-pages-heading-change">How do I get my end lease bond back?</span></h2>
      <p className="blog-pages-p">As a tenant in Australia, one of the most important tasks that you have to do before moving out of a rental property is to ensure a thorough end of lease clean. An end of lease clean is a type of cleaning that is required to be done before returning the keys to the landlord or the property manager. The purpose of an end of lease clean is to leave the property in the same condition as it was when you first moved in, minus normal wear and tear. This guide will provide a detailed understanding of the end of lease clean and why it is so important.</p>
      <h3 className="blog-pages-h3"> What is an end of lease clean? </h3>
      <p className="blog-pages-p">An end of lease clean is a deep cleaning service that is required by most landlords or property managers before returning the bond to the tenant. It involves cleaning the entire property from top to bottom, including areas that are often neglected during regular cleaning such as the oven, rangehood, and blinds. The main goal of an end of lease clean is to make the property look as good as new and to ensure that all areas are clean and hygienic.</p>

      <h3 className="blog-pages-h3">Why is it important?</h3>
      <p className="blog-pages-p">An end of lease clean is important for two main reasons: firstly, it is required by most landlords or property managers, and secondly, it is necessary to ensure that you receive your full bond refund. When you sign a lease agreement, you pay a bond as a security deposit to cover any damages or unpaid rent at the end of the tenancy. The bond is usually equivalent to four weeks' rent, and it is refundable if the property is left in good condition. An end of lease clean can help ensure that you get your full bond refund.</p>

      <h3 className="blog-pages-h3">Who is responsible for the end of lease clean?</h3>
      <p className="blog-pages-p">Typically, it falls on the tenant to make sure the place is left tidy and clean at the conclusion of the lease. This suggests that you are in charge of keeping the property's walls, floors, and ceilings clean. It is crucial to consult your tenancy agreement or get advice from your landlord or property management if you are unsure of what is necessary.</p>

      <h3 className="blog-pages-h3">DIY vs Professional Cleaners</h3>
      <p className="blog-pages-p">When it comes to end of lease cleaning, tenants often face the decision of whether to do it themselves or hire professional cleaners. While DIY cleaning may seem like a cost-effective option, it may not always be the best choice. In this section, we will compare the pros and cons of DIY cleaning versus hiring professional cleaners and provide tips on how to choose a reputable cleaning company.
        </p>
        <p className="blog-pages-p">Pros DIY cleaning</p>
         <p className="blog-pages-p">Cost-effective: DIY cleaning can save you money compared to hiring professional cleaners. Flexibility: You can clean at your own pace and schedule.
Personal satisfaction: Some people find satisfaction in doing the cleaning themselves.</p>
<p className="blog-pages-p">Cons DIY cleaning</p>
         <p className="blog-pages-p">Time-consuming: End of lease cleaning is a time-consuming task, especially if you are not experienced in it. Lack of equipment and expertise: DIY cleaning may not be as effective as professional cleaning since you may not have the necessary equipment and expertise. 
Risk of losing bond refund: If the cleaning is not up to the standard, you risk losing part of your bond refund. Benefits of hiring professional cleaners
Time-saving: Professional cleaners have the necessary equipment and expertise to complete the cleaning quickly and efficiently. Higher standard of cleaning: Professional cleaners are trained to clean to a higher standard, which may increase your chances of receiving your full bond refund.</p>
      <h3 className="blog-pages-h3"> The Cleaning Checklist</h3>
      <p className="blog-pages-p">You need to make a cleaning checklist to make sure your end-of-tenancy clean is comprehensive and up to code. You can stay organised and ensure you don't forget anything by using a cleaning checklist. We'll provide you a thorough cleaning checklist in this section so you can properly finish the end-of-tenancy clean.</p>
      <p className="blog-pages-p">1.Kitchen</p>
      <p className="blog-pages-p">
            Clean the range hood, microwave, stove, and oven<br/>
            Clean and sanitise the sink and faucets.<br/>
            Clean and sanitise the backsplash and counters.<br/>
            Clean and sanitise shelves, drawers, and cabinets.<br/>
            refrigerator that has been thoroughly cleaned<br/>
            Dishwasher interior and exterior cleaning<br/>
            Clean and sanitise the kitchen chairs and table.<br/>
            Clean and sanitise the flooring<br/>
</p>
      <p className="blog-pages-p">2.Bathroom</p>
      <p className="blog-pages-p">
          Clean and disinfect toilet (including base and behind)<br/>
          Clean and disinfect sink and taps<br/>
          Clean and disinfect shower, bathtub, and tiles<br/>
          Clean and disinfect cabinets, drawers, and shelves<br/>
          Clean and disinfect mirrors<br/>
          Clean and disinfect towel racks and toilet paper holder<br/>
          Clean and disinfect floors</p>
      <p className="blog-pages-p">3.Living areas and bedrooms</p>
      <p className="blog-pages-p">
          Clean and dust all surfaces, including shelves, tables, and cabinets<br/>
          Clean and dust blinds, curtains, and window sills<br/>
          Clean and disinfect light switches and doorknobs<br/>
          Vacuum carpets and rugs<br/>
          Mop hardwood or tiled floors<br/>
          Clean mirrors and glass surfaces</p>
      <p className="blog-pages-p">4.Outdoor areas</p>
      <p className="blog-pages-p">
        Sweep or hose down outdoor areas, including patios and balconies<br/>
        Clean and disinfect outdoor furniture<br/>
        Remove any rubbish or debris</p>
      <p className="blog-pages-p">5.Miscellaneous</p>
      <p className="blog-pages-p">
        Check for any marks or scuffs on walls and clean if necessary<br/>
        Replace any light bulbs that are not working<br/>
        Ensure that all appliances and fixtures are in working order<br/>
        Remove any personal belongings and rubbish</p>
      
    </div>
    </div>
    <Footer/>
   </div> 
  )
}

export default BlogCost