import React from 'react'
import HeaderSteam from '../components/HeaderSteam'
import Footer from '../components/Footer'
import "./Licensee.css"
import backlic from "../assets/lickbackTwo.png"
import TrippleIconsLic from '../components/TrippleIconsLic'
import WhyUsLic from '../components/WhyUsLic'




  


const Licensee = () => {

const phoneNumber = '0433310964'; // Replace this with the desired phone number

  const handleCallClick = () => {
    // Call the function to track the phone call conversion
    gtag_report_conversion();
    // Open the phone dialer with the provided phone number
    window.open(`tel:${phoneNumber}`);
  };

   // Define the Google Ads conversion tracking function
  const gtag_report_conversion = () => {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-11025934865/CvyMCNXEjJkZEJHUyYkp'
    });
  };


  return (
    <div className='main-lic-wrapper'>
        <HeaderSteam/>
        <img className='back-ground-lic' src={backlic} alt="nice looking kitchen" />
        <div className='content-lic'>
        <h1 className='lic-heading'>We are changing business ownership in Australia! Start your own Sprucely business from $1000 upfront and just $99 per week. Don't pay huge franchise fees with long term lock in contracts. </h1>
        <TrippleIconsLic/>
        <WhyUsLic/>

        <div className='chatbutton-join-wrapper' onClick={handleCallClick}>
        <button className='let-chat-join'  >Lets chat</button>
        </div>

      
        </div>
        <Footer/>
    </div>
  )
}

export default Licensee