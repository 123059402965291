import React, { useEffect, useRef, useState } from 'react';
import { RiStarSFill } from 'react-icons/ri';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import arone from '../assets/Reviews/revEight.png';
import arthree from '../assets/Reviews/revFour.png';
import arfour from '../assets/Reviews/revOne.png';
import arfive from '../assets/Reviews/revThree.png';


import './ReviewSection.css';

const MoreReviews = () => {

  const reviews = [arone, arthree, arfour, arfive,];
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const nextReview = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const prevReview = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const deltaX = touchEndX.current - touchStartX.current;
    if (Math.abs(deltaX) > 50) {
      if (deltaX > 0) {
        prevReview();
      } else {
        nextReview();
      }
    }
    touchStartX.current = 0;
    touchEndX.current = 0;
  };
  

  return (
    <div className="review-grid-container review-grid-container-more">
      
      <h3 className="review-heading happy">More happy <br/> customers</h3>
        <div className="stars-reviews">
        <RiStarSFill size={50} color="gold" />
        <RiStarSFill size={50} color="gold" />
        <RiStarSFill size={50} color="gold" />
        <RiStarSFill size={50} color="gold" />
        <RiStarSFill size={50} color="gold" />
        </div>
        
    <div className="image-review-more-container"
    onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}>
        
        <div className='fixed-arrows'>
        <IoIosArrowBack onClick={prevReview} size={30}  />
        <img className="review-more" src={reviews[currentIndex]} alt="Customer review" />
        <IoIosArrowForward onClick={nextReview} size={30} />
        </div>
        
      
   </div> 
   
   
    </div>
  );
};

export default MoreReviews;



