import React from 'react';
import "./Endofleaseblurb.css"
import "./SteamCleanTop.css"


const GeneralCleanblurb = () => {
  return (
    <div className="end-of-lease-cleaning">
      <h2>Professional Home Cleaning Services near you</h2>
      <p className='p-description'>Keeping your home clean and tidy is essential for a comfortable living environment. Whether you're too busy to handle the cleaning yourself or just want to maintain a pristine home, Sprucely Clean is here to help with our expert home cleaning services.</p>
      <p className='p-description'>Let our team of professionals take care of the cleaning while you focus on enjoying your home. We understand the importance of a thorough clean, and our dedicated team works diligently to ensure your home sparkles from top to bottom.</p>
      <p className='p-description'>For reliable home cleaning services in Melbourne, reach out to us today for a free quote. We guarantee satisfaction with our attention to detail and commitment to exceeding your expectations.</p>
      <p className='p-description'>In addition to general home cleaning, we offer a comprehensive range of services to keep your home looking its best, including carpet and window cleaning. We prioritize using eco-friendly products to ensure a safe and healthy environment for you and your family.</p>
      <p className='p-description'>Look no further than Sprucely Clean for all your home cleaning needs in Melbourne. With our experience and expertise, we tackle every cleaning task with efficiency and professionalism.</p>
      <p className='p-description'>Join the many satisfied homeowners who trust Sprucely Clean to keep their homes pristine. Contact us today to schedule your home cleaning or to learn more about our services.</p>
</div>

  );
}

export default GeneralCleanblurb;
