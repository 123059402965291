import React from "react";
import './Cleaningmsg.css'; 
import ProfileImage from "../assets/profileImage.png"

const Cleaningmsg = () => {
  return (
    <div className="message-container">
    <img className="profile-photo profile-com" src={ProfileImage} alt="profile of director"/>
    <div className="top-heading-pro-pic">
      <h2 className="message-heading">
      
        FED UP WITH CLEANERS?
      </h2>
      </div>
      <p className="message-paragraph">Dear fellow business owner,</p>
      <p className="message-paragraph">
        Have you ever arrived at the office, gym or common areas wondering if your cleaners even
        showed up the night before? Does that same stain or dust pile seem to
        linger for far too long? Perhaps your cleaners started strong, but now
        they're cutting corners and doing the bare minimum?
      </p>
      <p className="message-paragraph">
        I'm Shaun, your partnership manager at Sprucley. Having spent
        years within corporate, I've seen all sorts of shortcuts
        firsthand. Most cleaning companies dedicate about 5.6 hours to their
        initial cleaning session, but after a few months, that time dwindles to
        just 45 minutes.
      </p>
      <p className="message-paragraph">
        We ensure our cleaners are fairly
        compensated, so you'll never have to deal with lazy subcontractors. We
        tailor a detailed checklist for each client to guarantee a thorough
        clean every single time. Plus, we conduct weekly inspections and reports
        to ensure we're meeting our standards. If you're ready to partner with a
        cleaning company that simplifies your life and keeps your workplace
        spotless, it's time to switch to the best.
      </p>
    </div>
  );
};

export default Cleaningmsg;
