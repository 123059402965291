import React, { useState, useEffect } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import logo from "../assets/logo.png";
import { FaFacebookF } from 'react-icons/fa';
import { AiOutlineInstagram } from 'react-icons/ai';
import { ImPinterest2 } from 'react-icons/im';
import ContactUs from './ContactUs';





const HeaderSteam = () => {






  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const [showPopupOnClick, setShowPopupOnClick] = useState(false);

  const popupStyleButton = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    height:'100%',
    transform: showPopupOnClick ? 'translate(-50%, -50%)' : 'translate(-50%, -150%)',
    backgroundColor: 'black',
    display: showPopupOnClick ? 'block' : 'none',
    zIndex: 999,
  };

  const handleClose = () => {
    setShowPopupOnClick(false);
  };


  
  

  return (

    <div>
    <header className="header">
    <div className="popup" style={popupStyleButton}>

        <button className="close-button" onClick={handleClose}>
            X
          </button>
          <ContactUs/>
        </div>
    
      <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/"><img className="logo header-logo" src={logo} alt="logo"/></Link>
      
      <div className="icons-container">
      <a className="social-none" style={{textDecoration: 'none', color: 'inherit', }}  href="https://www.facebook.com/profile.php?id=100091332464688"><FaFacebookF /></a>
      <a className="social-none" style={{textDecoration: 'none', color: 'inherit', }}  href="https://www.instagram.com/"><AiOutlineInstagram /></a>
      <a className="social-none" style={{textDecoration: 'none', color: 'inherit', }}  href="https://www.pinterest.com.au/"><ImPinterest2 /></a>
     
      </div>
      <nav className={`nav ${isMenuOpen ? "open" : ""}`}>
        <ul>
          <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/about"><li className="nav__options">About</li></Link>
          <Link style={{textDecoration: 'none', color: 'inherit', }}><li className="nav__options" onClick={() => setShowPopupOnClick(true)}>Contact</li></Link>
          <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/news"><li className="nav__options">News</li></Link>

        </ul>
      </nav>
      <button className={`menu-button ${isMenuOpen ? "open" : ""}`} onClick={handleMenuClick}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </button>
      
    </header>

        

    </div>
  );
}

export default HeaderSteam;



