import React from 'react';
import imageDry from '../assets/moreGymThree.png'; // Import image1
import imageProTec from '../assets/officeTwo.png'; // Import image2
import imageStain from '../assets/comSteam.png'; // Import image1
import imageFlea from '../assets/comarea.png'; // Import image2
import imageDustMite from '../assets/gympic.png'; // Import image1
import imagePetOd from '../assets/office.png'; // Import image2

import './serviceInfoSection.css';

const ServiceInfoCom = () => {
  const images = [
  { 
    src: imageFlea, 
    alt: 'Fleas', 
    title: 'Body Corporate Cleaning', 
    description: 'Maintain a clean and welcoming environment in your apartment blocks and common areas with our specialized body corporate cleaning services. We focus on keeping shared spaces such as lobbies, hallways, elevators, and other communal areas pristine and hygienic. Our professional team is dedicated to removing dirt, stains, and allergens, ensuring a pleasant atmosphere for residents and visitors alike.' 
  },
  { 
    src: imageDustMite, 
    alt: 'Dust mite', 
    title: 'Health Club Deep Clean', 
    description: 'Keep your health club in top condition with our comprehensive deep cleaning services. Our expert team ensures every corner is spotless, promoting a clean and healthy space for your members to enjoy their workouts and relaxation.' 
  },
  { 
    src: imagePetOd, 
    alt: 'Dog on carpet with pet stain', 
    title: 'End of Lease Office Cleaning', 
    description: 'Our end-of-lease office cleaning services ensure your workspace is left in pristine condition, ready for the next tenant. We provide thorough and meticulous cleaning to meet all lease requirements, helping you secure your bond return. Trust us to leave your office spotless, from carpets and windows to kitchens and bathrooms, ensuring a smooth and stress-free transition.' 
  },
  { 
    src: imageDry, 
    alt: 'Carpet dry cleaning', 
    title: 'Functional Training Studio Clean', 
    description: 'Maximize the cleanliness and safety of your functional training studio with our professional cleaning services. We focus on removing dirt, sweat, and germs, ensuring a fresh and hygienic workout space for your clients.' 
  },
  { 
    src: imageProTec, 
    alt: 'Carpet stains', 
    title: 'Office Cleaning', 
    description: 'Ensure your office space is spotless and welcoming with our dedicated office cleaning services. We specialize in maintaining a clean and sanitary environment, promoting a healthy and productive workspace for your employees. From workstations to common areas, our team ensures that every corner of your office is kept in top condition.' 
  },
  { 
    src: imageStain, 
    alt: 'Carpet staining', 
    title: 'Commercial Steam Cleaning', 
    description: 'Our commercial steam cleaning services provide deep and thorough cleaning for your business, ensuring a spotless and hygienic environment. Using state-of-the-art steam cleaning equipment, we effectively remove dirt, stains, and bacteria from a variety of surfaces, enhancing the appearance and cleanliness of your facility. Whether its carpets, upholstery, or hard surfaces, our team ensures your space looks and feels refreshed.' 
  },
  
  // Add more images here
];

  return (
    <div className="service-info-section">
      {images.map((image, index) => (
        <div key={index} className="service-item">
          <img src={image.src} alt={image.alt} />
          <h3>{image.title}</h3>
          <p>{image.description}</p>
        </div>
      ))}
    </div>
  );
};

export default ServiceInfoCom;
