import { Helmet } from "react-helmet"
import "./BookingClean.css";
import HeaderSteam from "../components/Header";
import SteamCleanTop from "../components/SteamCleanTop";
import SteamCleanTopTwo from "../components/SteamCleanTopTwo";
import Footer from "../components/Footer";
import WorkSlides from "../components/WorkSlides";
import ReviewSection from "../components/ReviewSectionSteam";
import Profile from "../components/Profile";
import VideoPlayer from "../components/VideoPlayer";
import MoreReviews from '../components/MoreReviews';
import ServiceInfoSection from "../components/ServiceInfoSection";
import { IoMdStar } from "react-icons/io";
import icon from "../assets/iconG.png"
import { Link } from "react-router-dom";



const BookingSteam = () => {


  return (
    <div>
    <Helmet>
          <meta name="description" content="We offer general and end of lease cleaning service, from carpet steam cleaning through to cleaning."/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11025934865"></script>
        </Helmet>
            <HeaderSteam/>
            <SteamCleanTop/>
            <div className="rev-sec">
            <ReviewSection/>
            </div>

            <div className="steam-booking-homepage mar-rev-steam">
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/SteamForm"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            </div>

            <WorkSlides/>
            <SteamCleanTopTwo/>
            <div className="review-review-steam">
            
            <div className="vid-wrapper-steam">
            <VideoPlayer/>
            </div>
            
            <div className='more-rev'>
            <MoreReviews/>
            </div>

            <div className="steam-booking-homepage top-three">
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/SteamForm"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            </div>

            

            <div className='info-steam-sec'>
            <ServiceInfoSection/>
            </div>

            <div className="steam-booking-homepage mar-buttom-steam">
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/SteamForm"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            </div>

            <div className="review-bottom steam-rating rate-mar-steam">
            <div className="review-content-bottom">
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <img className="gimage" src={icon} alt="logo for customer rating"/>
           </div>

           </div>

           
            </div>
            <Profile/>
            <div className="foot"><Footer/></div> 
       
    </div>
  );
};

export default BookingSteam;







