import { Helmet } from "react-helmet"
import "./BookingClean.css";
import HeaderSteam from "../components/Header";
import Footer from "../components/Footer";
import { IoMdStar } from "react-icons/io";
import icon from "../assets/iconG.png"
import { Link } from "react-router-dom";
import GymRev from "../components/GymRev";
import WorkSlidesRevgym from "../components/WorkSlidesRevgym";
import Cleaningmsg from "../components/Cleaningmsg";
import ComTop from "../components/ComTop";
import ComTwo from "../components/ComTwo";
import ServiceInfoCom from "../components/ServiceInfoCom";
import ProfileCom from "../components/ProfileCom";
import TrippleIconsGym from "../components/TrippleIconsGym";




const Commercial = () => {


  return (
    <div>
    <Helmet>
          <meta name="description" content="We offer general and end of lease cleaning service, from carpet steam cleaning through to cleaning."/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11025934865"></script>
        </Helmet>
            <HeaderSteam/>
            <ComTop/>
            <Cleaningmsg/>
            
       
            <div className="rev-sec">
            <GymRev/>
            </div>

            <div className="steam-booking-homepage mar-rev-steam">
            <h2>90 DAY RISK FREE TRIAL</h2>
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/book-a-walk-through"><button className='p-description end-lease-link'>GET A QUOTE</button></Link>
            </div>
            

            <WorkSlidesRevgym/>
            
            <ComTwo/>
            <div className="review-review-steam">
            
          
              <TrippleIconsGym/>
          
            <div className="steam-booking-homepage top-three">
              
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/book-a-walk-through"><button className='p-description end-lease-link risk-but'>90 DAY RISK FREE TRIAL</button></Link>
            </div>

            

            <div className='info-steam-sec'>
            <ServiceInfoCom/>
            </div>

            <div className="steam-booking-homepage mar-buttom-steam">
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/book-a-walk-through"><button className='p-description end-lease-link'>GET A QUOTE</button></Link>
            </div>

            <div className="review-bottom steam-rating rate-mar-steam">
            <div className="review-content-bottom">
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <img className="gimage" src={icon} alt="logo for customer rating"/>
           </div>

           </div>

           
            </div>
            <ProfileCom/>
            <div className="foot"><Footer/></div> 
       
    </div>
  );
};

export default Commercial;







