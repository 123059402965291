import React, { useState, useEffect } from 'react';
import './WorkSlides.css';
import workOne from "../assets/workcarp.png"
import workTwo from '../assets/workTwo.png'
import workThree from "../assets/sofacarp.png"
import workFour from "../assets/roomcarp.png"
import workFive from "../assets/carppic.png"
import workSix from "../assets/beforcarp.png"
import { AiFillStar } from 'react-icons/ai';


const images = [
  workOne,
  workTwo,
  workThree,
  workFour,
  workFive,
  workSix,
 
 

  
  // Add other image names here
];

const reviews = [
  {
    text: "Needed steam clean carpet stain removal, great result",
    rating: 5, // 5 stars
    name:"Avan.I"
  },
  {
    text: "Two bedrrom carpet steam clean, carpets look great",
    rating: 5, // 5 stars
    name:"John.C"
  },
  {
    text: "Great work on steam cleaning my sofa.",
    rating: 5, // 3 stars
    name:"Cassie.M"
  },
  {
    text: "End of lease carpet steam clean, full bond back.",
    rating: 5, // 3 stars
    name:"Amanda.T"
  },
  {
    text: "Got my full bond back, carpet steam cleaned to orginal condition.",
    rating: 5, // 3 stars
    name:"Joseph.H"
  },

  {
    text: "Pet hair removal and deep carpet steam clean, place looks great",
    rating: 5, // 3 stars
    name:"Noosh.K"
  },

  
  // Add more review objects as needed
];

const WorkSlides = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change slide every 3 seconds (3000 milliseconds)

    return () => {
      clearInterval(interval);
    };
  }, []); // Empty dependency array ensures effect runs once after initial render


  const reviewIndex = currentIndex % reviews.length;
  const reviewData = reviews[reviewIndex];
  const reviewRating = reviewData ? reviewData.rating : 0;

  return (
    <div>
    <p className='testimonail'>"Satisfied Customers"<br/><span className='melb-across'>Across the Melbourne area</span></p>
    <div className="work-slides-container">
    
      <img className="work-images" src={`${images[currentIndex]}`} alt={`work ${currentIndex + 1}`} />
      <div className="review-container">
      <p className='reviews-p'>" {reviewData.text} "</p>
      <div className='rating-work'>
      {[...Array(reviewRating)].map((_, index) => (
              <AiFillStar key={index} size={30} color='gold' />
            ))}
      </div>
      <p className='name-rate'>{reviewData.name}</p>
      </div>
    </div>
    
   </div> 
  );
};

export default WorkSlides;
