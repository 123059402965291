import React, { useEffect } from 'react';
import "./TrippleIcons.css"
import { BsArrowUpCircle } from 'react-icons/bs';
import { BsPersonCircle } from 'react-icons/bs';
import { BsCheckCircle } from 'react-icons/bs';
import JumpingButton from './JumpingButton';
import EndLeaseButton from './EndLeaseButton';




const TrippleIcons = () => {

    const handleIntersection = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('fade-in');
      observer.unobserve(entry.target);
    }
  });
};

useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '0px',
      threshold: 0.3,
    });
    const container = document.querySelector('.tripple-icons-container');
    observer.observe(container);

    return () => {
      observer.unobserve(container);
    };
  }, []);


  return (
    <div className='icons-main-wrapper'>
    <div className="tripple-icons-container">
        <div className="icons-wrapper">
            <div className="icons-boxes">
            <BsArrowUpCircle color="#1725a" size={140}/>
            <h3 className="icons-heading">Live Booking</h3>
            <p className="icons-p">On demand booking with no hidden rates<br/>We provide a fixed price service</p>
            </div>
            <div className="icons-boxes">
            <BsPersonCircle color="#1725a" size={140}/>
            <h3 className="icons-heading">Cleaner allocation</h3>
            <p className="icons-p">Once booking is confirmed your cleaner<br/> will be in contact within 24hrs.</p>
            </div>
            <div className="icons-boxes">
           <BsCheckCircle color="#1725a" size={140}/>
            <h3 className="icons-heading">Book and pay later</h3>
            <p className="icons-p">Book now and pay later <br/> once job is completed, no addtional hidden rates.</p>
        </div>   
    </div>
  
    </div>
   
    </div>
  )
}

export default TrippleIcons