import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga";
import './GiveGoogleReview.css'; // Import your CSS file for styling
import emailjs from "@emailjs/browser";
import Header from './Header';



const GiveGoogleReview = () => {
  useEffect(() => {
  ReactGA.initialize('G-ECHQXBFYLM'); 
  // Report page view
  ReactGA.pageview(window.location.pathname + window.location.search);
}, []);
useEffect(() => {
   console.log(window.location.pathname)
  })


  const [isPositive, setIsPositive] = useState(null);
  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackSent, setFeedbackSent] = useState(false); 

  const handleFeedback = (isPositive) => {
    setIsPositive(isPositive);
    if (isPositive) {
      // Redirect to the Google review URL
      window.location.href = "https://g.page/r/CUahC1XOlAGiEBI/review";
    }
  };

  const handleFeedbackTextChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const sendFeedbackEmail = () => {
    // Replace these with your email.js parameters
    const serviceID = 'service_k4aiwmr';
    const templateID = 'template_lw174aa';
    const userID = 'Fh0Y9YM-DloiKhGif';

    emailjs.init(userID);

    const templateParams = {
      from_name: 'User',
      to_name: 'Your Name',
      subject: 'Feedback from User',
      message_html: feedbackText,
    };

    emailjs.send(serviceID, templateID, templateParams).then(
      (response) => {
        console.log('Feedback sent successfully:', response);
        // Optionally, you can reset the feedback text area or display a confirmation message
      },
      (error) => {
        console.error('Error sending feedback:', error);
        // Handle error, e.g., display an error message to the user
      }
    ).finally(() => {
    setFeedbackSent(true); // Set feedbackSent to true whether success or error
  });
};

  return (
    <div>
      <Header />
      <div className="give-google-review-container">
        {isPositive === null ? (
          <div className="feedback-question">
            <h1>Was your experience with Sprucely positive?</h1>
            <button className="feedback-button" onClick={() => handleFeedback(true)}>
              Yes
            </button>
            <button className="feedback-button" onClick={() => handleFeedback(false)}>
              No
            </button>
          </div>
        ) : isPositive ? (
          <div className="positive-feedback">
            {/* Redirecting to the Google review URL */}
          </div>
        ) : (
          <div className="negative-feedback">
            {feedbackSent ? ( // Check if feedback has been sent
              <div className="feedback-sent-message">
                <h2>Your feedback has been sent.</h2>
              </div>
            ) : (
              <>
                <h2>Thank you for your feedback.</h2>
                <p>We appreciate your input. Please share your comments below:</p>
                <textarea
                  rows="4"
                  cols="50"
                  value={feedbackText}
                  onChange={handleFeedbackTextChange}
                  placeholder="Write your feedback here..."
                />
                <button className="feedback-button" onClick={sendFeedbackEmail}>
                  Send Feedback
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};


export default GiveGoogleReview;


