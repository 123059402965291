
import './JumpingButton.css'
import { Link } from 'react-router-dom'
import { SlCalender } from "react-icons/sl";

const JumpingButton = () => {

  
 
  return (
    <div className='button-wrapper'>
         <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/BookingPage"> <button className="jumping-button jumping-button-width lease-button">Build Quote <SlCalender size={20} className='icon-contact'/></button></Link>
        </div>
 
  )
}

export default JumpingButton