import React, { useState, useEffect } from 'react';
import carpetRoom from "../assets/CarpetRoom.png"
import rating from "../assets/ratingPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css"
import { Link } from 'react-router-dom';

const GeneralCleanTop = () => {


  const [iconSize, setIconSize] = useState(30);

  useEffect(() => {
    const handleResize = () => {
      // Check screen width and set icon size accordingly
      if (window.innerWidth <= 500) {
        setIconSize(20); // Set size for smaller screens
      } else {
        setIconSize(30); // Set default size for larger screens
      }
    };

    // Call handleResize on initial load
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on initia
  return (
    <div className='carpet-wrapper'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Nice looking styled room with carpet"/>
        </div> 
        <div className='carpet-description'>
          <h2 className='rec-head'>Transform your home with our trusted general cleaning services in Melbourne.</h2>
          <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>Zero hassle with our thorough cleaning process</p>
          <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>Flexible scheduling, available 24/7.</p>
          <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>Enjoy a 10% discount on your first clean</p>
          <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>Affordable pricing starting from $110</p>
          <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>Comprehensive cleaning checklist for your peace of mind</p>
          <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>Serving homes across Melbourne</p>
          <p className='p-description'><AiFillCheckCircle size={iconSize} color='#3AAFA9'/>Consistently rated 5 stars by our satisfied customers</p>
          <p className='p-description custom-p'><span className='finger'>👇</span> Get a custom quote here and enjoy an additional 10% discount <span className='finger'>👇</span></p>
          <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/General-Cleaning-Melbourne-quote"><button className='p-description end-lease-link'>BOOK NOW</button></Link>
    
    <img className="rating-img" src={rating} alt="rating symbol"/>
</div>

        
    </div>
  )
}

export default GeneralCleanTop