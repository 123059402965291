import React, { useState } from 'react';
import "./Faq.css"

const FaqGeneral = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'How much does General cleaning cost?',
      answer: 'The cost General cleaning can vary depending on the size of the property and specific requirements. Price ranges from $110 for a one bed, two bedroom $150, and three plus bedroom ranges from $195 to $400. Price for end of lease cleaning also depends on level of deep cleaning needed.'
    },
    {
    question: 'What if the homeowner is not satisfied with the cleaning service?',
    answer: 'If the homeowner is not happy with the cleaning, we will address any concerns promptly and free of charge within a specified timeframe, as outlined in our terms and conditions.'
  },
  {
    question: 'Do you guarantee 100% satisfaction with your cleaning services?',
    answer: 'Yes, we strive for 100% customer satisfaction with our cleaning services. We ensure quality results and address any issues promptly to meet your expectations.'
  },
  {
    question: 'How long does a general home cleaning usually take?',
    answer: 'The duration of a general home cleaning depends on the size and specific requirements of the property. On average, it may take a one to two hours to complete, with larger homes requiring more time and attention to detail.'
  },
  {
    question: 'Do homeowners need to be present during the cleaning service?',
    answer: 'No, homeowners do not need to be present during the cleaning. We can arrange access to the property through alternate means, such as a key exchange or instructions provided beforehand.'
  },
  {
    question: 'Are laundry services included in your standard home cleaning package?',
    answer: 'Yes, laundry services can be included as part of our standard home cleaning package at a addtional charge, providing convenience and comprehensive cleaning solutions.'
  },
  {
    question: 'Do you provide cleaning supplies for your home cleaning services?',
    answer: 'Yes, we supply cleaning supplies at no extra charge.'
  },
  {
    question: 'What is your policy if a homeowner is dissatisfied with the cleaning service?',
    answer: 'Customer satisfaction is our top priority. If a homeowner is not satisfied with the cleaning, we will promptly return to address any concerns and ensure their expectations are met.'
  },
  {
    question: 'Do you offer steam cleaning services for carpets in homes?',
    answer: 'Yes, we offer steam cleaning services as an optional add-on for carpets in homes, providing flexibility and tailored cleaning solutions to meet individual needs.'
  },
  {
    question: 'How long does steam cleaning of carpets typically take in a home?',
    answer: 'The duration of steam cleaning for carpets in a home can vary depending on the size of the area being cleaned. On average, it may take anywhere from 45 minutes to a few hours to complete, ensuring thorough and effective cleaning.'
  },
  {
    question: 'Do you have insurance coverage and a valid business registration number for your cleaning services?',
    answer: 'Yes, we are fully insured and hold a valid business registration number (ABN). This ensures peace of mind for homeowners and compliance with legal requirements, safeguarding both parties in the cleaning process.'
  }
    
  ];

  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2>
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 className='q-head' onClick={() => toggleAnswer(index)}>{faq.question}</h3>
          {activeIndex === index && <p>{faq.answer}</p>}
        </div>
      ))}
    </div>
  );
}

export default FaqGeneral;
