import React, { useEffect } from 'react'
import { Helmet } from "react-helmet"
import './About.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import JumpingButton from '../components/JumpingButton';
import { AiOutlineMinus } from 'react-icons/ai';
import ReactGA from "react-ga";

function About() {

  useEffect(() => {
    // Google Tag for remarketing and conversion tracking
    window.gtag('config', 'AW-11025934865');
  }, []);

  
useEffect(() => {
  ReactGA.initialize('G-ECHQXBFYLM'); 
  // Report page view
  ReactGA.pageview(window.location.pathname + window.location.search);
}, []);
useEffect(() => {
   console.log(window.location.pathname)
  })



  useEffect(() => {
  
  ReactGA.initialize('UA-264556303-1');
  //to report a page view
  ReactGA.pageview(window.location.pathname + window.location.search)
}, []);
useEffect(() => {
   console.log(window.location.pathname)
  })

  return (
    <div className="about-container">
    <Helmet>
          <meta name="description" content="We offer general and end of lease cleaning service."/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11025934865"></script>
        </Helmet>
    <Header/>
  <div className="blog-container-pages blog-container-about">
     <div className="heading-content">
    <div className="heading-overlay">
      <h1 className="heading">Australia home <br />cleaning <span className="color__change"><br/>experts.</span><br/><AiOutlineMinus /></h1>
      <div className="jump-container">
        <JumpingButton/>
      </div>
    </div>  
  </div>
    <div className="flex-blog-section">
      <h2>Welcome to home Sprucely!</h2>
      <p className="blog-pages-p">Welcome to Sprucely, a group of experts committed to provide top-notch home services around the nation. Because we are aware of the frustrations associated with Australia's unregulated trades, we make every effort to assure your safety by carefully screening and investigating each of our home service professionals.</p>
      <p className="blog-pages-p">Promoting safety and security within the home services sector is something we are deeply committed to. We go above and above to make sure that our specialists are completely qualified and trained because we really think that everyone deserves access to dependable and trustworthy home service providers. It is the wild west out there and we eliminate the challenge of finding safe reliable trades from cleaning, lawn mowing, landscaping through to home renovation services.</p>
      <p className="blog-pages-p">Our commitment to safety extends beyond just background checks. We are also dedicated to providing our clients with high-quality service that they can rely on. From cleaning, landscaping, garden care, minor repairs to major renovations, we take great care to ensure that our contractors work is completed to the highest standards.</p>
      <p className="blog-pages-p">In todays market anyone can run down to their local trade store by some equipment, create a business name and post some FB ads, causing unregulated trades flooding the home services market. Sprucely are always looking for ways to enhance our services and go above and beyond for our clients. We are certain that by providing excellent service and being committed to safety, we can become Australia's top choice for home services.</p>
    </div>
    </div>
    <div className="about-image-container">
      <div className="about-div-image-container">
    
      </div>
      
    </div>
    <div className="footer-about">
    <Footer/>
    </div>
    </div>
  );
}

export default About;
