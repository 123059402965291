import React from 'react'
import carpetRoom from "../assets/CarpetRoom.png"
import rating from "../assets/ratingPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css"
import { Link } from 'react-router-dom';

const GeneralTop = () => {
  return (
    <div className='carpet-wrapper'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Nice looking styled room with carpet"/>
        </div> 
        <div className='carpet-description'>
         <h6>Fill in your details below for an instant quote, our quotes are fixed price with no hidden additional hourly rates.
         We offer General and spring cleaning services across Melbourne's northern suburbs and CBD.. </h6>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>General and spring cleaning services</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>10% off first clean, fast booking and instant quote</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Fixed rate service, no hidden hourly rate</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>On demenad cleaners available now</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>All cleaners vetted and insurance</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>5 star customer rating</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>General clean, spring clean and deep clean</p>
            <p className='p-description custom-p'><span className='finger'>👇</span> Custom quote here + 10% dicount <span className='finger'>👇</span></p>
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/GeneralBookPage"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            
        <img className="rating-img" src={rating} alt="rating symbol"/>
        </div>
        
    </div>
  )
}

export default GeneralTop