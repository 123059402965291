import { Helmet } from "react-helmet"
import "./BookingClean.css";
import HeaderSteam from "../components/Header";
import Footer from "../components/Footer";
import Profile from "../components/Profile";
import ServiceInfoSection from "../components/ServiceInfoSectionair";
import { IoMdStar } from "react-icons/io";
import icon from "../assets/iconG.png"
import { Link } from "react-router-dom";
import AirBnbRev from "../components/AirBnbRev";
import Airbnbtop from "../components/Airbnbtop";
import WorkSlidesRevAirbnb from "../components/WorkSlidesRevAirbnb";
import AirbnbTwo from "../components/AirbnbTwo";




const BookingAirbnb = () => {


  return (
    <div>
    <Helmet>
          <meta name="description" content="We offer general and end of lease cleaning service, from carpet steam cleaning through to cleaning."/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11025934865"></script>
        </Helmet>
            <HeaderSteam/>
            <Airbnbtop/>
       
            <div className="rev-sec">
            <AirBnbRev/>
            </div>

            <div className="steam-booking-homepage mar-rev-steam">
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/BookingGeneral"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            </div>

            <WorkSlidesRevAirbnb/>
            <AirbnbTwo/>
            <div className="review-review-steam">
            
          

            <div className="steam-booking-homepage top-three">
              <h2>BOOK NOW 20% DICOUNT</h2>
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/BookingGeneral"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            </div>

            

            <div className='info-steam-sec'>
            <ServiceInfoSection/>
            </div>

            <div className="steam-booking-homepage mar-buttom-steam">
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/BookingGeneral"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            </div>

            <div className="review-bottom steam-rating rate-mar-steam">
            <div className="review-content-bottom">
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <img className="gimage" src={icon} alt="logo for customer rating"/>
           </div>

           </div>

           
            </div>
            <Profile/>
            <div className="foot"><Footer/></div> 
       
    </div>
  );
};

export default BookingAirbnb;







