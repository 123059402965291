import React from 'react';

import arone from '../assets/arone.png';
import artwo from '../assets/artwo.png';
import arthree from '../assets/arthree.png';

import './ReviewSection.css';


const ReviewEnd = () => {
  

  return (
    <div className="review-grid-container rev-end-con">
      
    <div className="image-review-container">
        <img className="review-img" src={arone} alt="Customer review"/>
        <img className="review-img" src={artwo} alt="Customer review"/>
        <img className="review-img" src={arthree} alt="Customer review"/>
       
   </div> 
   

    </div>
  );
};

export default ReviewEnd;



