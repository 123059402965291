import React from 'react';
import imageDry from '../assets/moreGymThree.png'; // Import image1
import imageProTec from '../assets/boxGym.png'; // Import image2
import imageStain from '../assets/moreGym.png'; // Import image1
import imageFlea from '../assets/lesGym.png'; // Import image2
import imageDustMite from '../assets/gympic.png'; // Import image1
import imagePetOd from '../assets/mattfloor.png'; // Import image2

import './serviceInfoSection.css';

const ServiceInfoGym = () => {
  const images = [
  { 
    src: imageFlea, 
    alt: 'Fleas', 
    title: 'Leisure Centre Cleaning', 
    description: 'Ensure your leisure centre remains inviting and hygienic with our professional cleaning services. We specialize in thorough cleaning processes that effectively remove dirt, stains, and allergens, creating a pristine and welcoming environment for your visitors.' 
  },
  { 
    src: imageDustMite, 
    alt: 'Dust mite', 
    title: 'Health Club Deep Clean', 
    description: 'Keep your health club in top condition with our comprehensive deep cleaning services. Our expert team ensures every corner is spotless, promoting a clean and healthy space for your members to enjoy their workouts and relaxation.' 
  },
  { 
    src: imagePetOd, 
    alt: 'Dog on carpet with pet stain', 
    title: 'Pilates Studio Sanitation', 
    description: 'Our specialized cleaning services for pilates studios ensure a serene and hygienic environment. Trust us to provide meticulous cleaning, maintaining the calm and clean atmosphere your clients expect.' 
  },
  { 
    src: imageDry, 
    alt: 'Carpet dry cleaning', 
    title: 'Functional Training Studio Clean', 
    description: 'Maximize the cleanliness and safety of your functional training studio with our professional cleaning services. We focus on removing dirt, sweat, and germs, ensuring a fresh and hygienic workout space for your clients.' 
  },
  { 
    src: imageProTec, 
    alt: 'Carpet stains', 
    title: 'Boxing Gym Maintenance', 
    description: 'Keep your boxing gym in fighting shape with our dedicated cleaning services. We specialize in maintaining a clean and sanitary environment, allowing your athletes to train in a safe and healthy space.' 
  },
  { 
    src: imageStain, 
    alt: 'Carpet staining', 
    title: '24/7 Gym Cleaning', 
    description: 'Our 24/7 gym cleaning services ensure your facility is always ready for members, day or night. We provide thorough cleaning to maintain a spotless and hygienic environment, enhancing the workout experience for all.' 
  },
  
  // Add more images here
];

  return (
    <div className="service-info-section">
      {images.map((image, index) => (
        <div key={index} className="service-item">
          <img src={image.src} alt={image.alt} />
          <h3>{image.title}</h3>
          <p>{image.description}</p>
        </div>
      ))}
    </div>
  );
};

export default ServiceInfoGym;
