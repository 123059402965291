import React from 'react'
import carpetRoom from "../assets/whyUsPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css";
import logoCraig from "../assets/3000.png"

const WhyUsLic = () => {
  return (
    <div className='carpet-wrapper why-us-container'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Nice looking styled kitchen"/>
        </div> 
        <div className='carpet-description'>
          <h6>Everyone dreams to start their own business to be in control of their own schedule, but for many the barriers of upfront cost and business experience put this dream on hold.</h6>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>No large franchise cost</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>No lengthy onboarding process</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>No complicated induction process</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>No long term lock in contracts</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>No gray areas, we are 100% transparent </p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>100% support all the way</p>
            <p className='p-description-partners'>Unlike the traditional franchise model, we do not lock in for a 2 to 4 year contract, we are Australia's most flexible business model. With a low cost commitment we will work with you to build a part time to full time business giving you time back with friends and family. </p>
            
      
        </div>
        
    </div>
  )
}

export default WhyUsLic