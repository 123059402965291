import React from 'react';
import './Profile.css';
import ProfileImage from "../assets/profileImage.png"
import { HiOutlineMinus } from 'react-icons/hi';


const Profile = () => {
  return (
    <div className="profile">

     <div className="profile-info-one">
        <img className="profile-photo" src={ProfileImage} alt="profile of director"/>
       </div>
            
        <div className="profile-info-two">
            <p className="description">
            <h5 className="director-name">Shaun Van Amersfoort</h5>
            <h5 className="director-name-profile">Client Relationship manager<br/><HiOutlineMinus size={30}/></h5>
            
                <span className="director-p">My mission is to create a high standard service model that gives you the ability to
                customize your own cleaning service based on your budget. At Sprucely, we deliver a high-quality clean that suits your budget. 
                Our fixed pricing service and live custom quote builders give you flexibility to create a job request that suits your budget 
                and provides you with reassurance that the service will be delivered with no hidden hourly rate costs.</span>

            </p>
        </div>
    </div>
  );
};

export default Profile;
