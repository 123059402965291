
import React from 'react';
import CarpetMp4 from "../assets/steamCleanTwo.mp4"
import './VideoPlayer.css';

const VideoPlayer = () => {
  return (
    <div className="video-container">
      <video width="540"  autoPlay muted loop>
        <source className="carpet-room" src={CarpetMp4} type="video/mp4" alt="Carpet being cleaned"/>
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
