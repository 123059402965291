import React from 'react';
import imageDry from '../assets/imageDry.png'; // Import image1
import imageProTec from '../assets/imageProTec.png'; // Import image2
import imageStain from '../assets/imageStain.png'; // Import image1
import imageFlea from '../assets/moresix.png'; // Import image2
import imageDustMite from '../assets/laundry.png'; // Import image1
import imagePetOd from '../assets/review6.png'; // Import image2

import './serviceInfoSection.css';

const ServiceInfoSectionair = () => {
  const images = [
    { src: imageFlea, alt: 'Fleas', title: 'Airbnb cleaning', description: 'Maximize the lifespan of your Airbnb property with our 5-star Airbnb Cleaning service. Our expert cleaning process rejuvenates and revitalizes surfaces, removing dirt, stains, and allergens effectively. Trust our team to restore your property to its pristine condition, ensuring a fresh and inviting environment for your guests.' },
    { src: imageDustMite, alt: 'Dust mite', title: 'Onsite and Offsite Laundry service', description: 'Maximize the convenience of your Airbnb property with our 5-star Onsite and Offsite Laundry service. Our expert laundry process ensures fresh linens for your guests, whether its onsite or offsite. Trust our team to handle all your laundry needs, providing a seamless experience for you and your guests.' },
    { src: imagePetOd, alt: 'Dog on carpet with pet stain', title: 'Priority airbnb cleaning service', description: 'Our expert team is available at short notice to ensure your property is always guest-ready. Trust us to provide prompt and thorough cleaning, ensuring a seamless experience for you and your guests.' },
    { src: imageDry, alt: 'Carpet dry cleaning', title: 'Airbnb carpet cleaning service', description: 'Maximize the lifespan of your carpets and Airbnb property with our professional Carpet Steam Cleaning service. Our expert steam cleaning process rejuvenates and revitalizes carpets, removing dirt, stains, and allergens effectively. Trust our team to restore your carpets to their pristine condition, ensuring a fresh and healthy environment for your home or business.' },
    { src: imageProTec, alt: 'Carpet stains', title: 'Airbnb carpet protection', description: 'we offer a protective solution to shield your airbnb carpet against spills and stains post-cleaning. This safeguarding layer can be applied after the cleaning procedure. Feel free to inquire for more details!' },
    { src: imageStain, alt: 'Carpet staining', title: 'Airbnb carpet steam clean stain removal', description: 'A stain on the carpet is like a stain on the mind, you probably won’t get over it unless it’s gone. Sprucely Clean has got what it takes to make those stains disappear like they never happened. With the use of non-toxic chemicals and the skilful persistence of our team members, the stains won’t see the light of another day.' },
    
    // Add more images here
  ];

  return (
    <div className="service-info-section">
      {images.map((image, index) => (
        <div key={index} className="service-item">
          <img src={image.src} alt={image.alt} />
          <h3>{image.title}</h3>
          <p>{image.description}</p>
        </div>
      ))}
    </div>
  );
};

export default ServiceInfoSectionair;
