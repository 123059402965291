import React, { useEffect } from 'react';
import "./News.css"
import BlogSection from "../components/BlogSection"
import Header from '../components/Header'
import Footer from '../components/Footer'
import ReactGA from "react-ga";
import { Helmet } from "react-helmet"

const News = () => {

  
 useEffect(() => {
  ReactGA.initialize('G-ECHQXBFYLM'); 
  // Report page view
  ReactGA.pageview(window.location.pathname + window.location.search);
}, []);
useEffect(() => {
   console.log(window.location.pathname)
  })

  return (
    <div className="news-wrapper-main">
    <Helmet>
        <meta name="description" content="We offer lawn mowing,landscaping, graden care, home maintenance and home cleaning services."/>
    </Helmet>
    <Header/>
    <div className="content-news">
    <BlogSection/>
    </div>
    <Footer/>
   </div>
  )
}

export default News