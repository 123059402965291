import React, { useEffect } from 'react';
import "./TrippleIcons.css"
import { IoIosPhonePortrait } from 'react-icons/io';
import { BsPersonCircle } from 'react-icons/bs';
import { BsCheckCircle } from 'react-icons/bs';
import JumpingButton from './JumpingButton';
import EndLeaseButton from './EndLeaseButton';




const TrippleIconsLic = () => {

    const handleIntersection = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('fade-in');
      observer.unobserve(entry.target);
    }
  });
};

useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '0px',
      threshold: 0.3,
    });
    const container = document.querySelector('.tripple-icons-container');
    observer.observe(container);

    return () => {
      observer.unobserve(container);
    };
  }, []);


  return (
    <div className='icons-main-wrapper'>
    <div className="tripple-icons-container">
        <div className="icons-wrapper">
            <div className="icons-boxes">
            <IoIosPhonePortrait color="#1725a" size={140}/>
            <h3 className="icons-heading">Lets chat</h3>
            <p className="icons-p">Book a call<br/>with a our team.</p>
            </div>
            <div className="icons-boxes">
            <BsPersonCircle color="#1725a" size={140}/>
            <h3 className="icons-heading">Information session</h3>
            <p className="icons-p">Get informed with a 10min discovery<br/> call.</p>
            </div>
            <div className="icons-boxes">
           <BsCheckCircle color="#1725a" size={140}/>
            <h3 className="icons-heading">Subscribe</h3>
            <p className="icons-p">Pay your weekly fee, <br/> learn and earn.</p>
        </div>   
    </div>
  
    </div>
   
    </div>
  )
}

export default TrippleIconsLic