import React from 'react'
import arone from '../assets/genOne.png';
import artwo from '../assets/genTwo.png';
import arthree from '../assets/genThree.png';
import arfour from '../assets/genFour.png';
import arfive from '../assets/genFive.png';
import arsix from '../assets/genSix.png';
import arseven from '../assets/genSeven.png';
import areight from '../assets/genEight.png';
import arnine from '../assets/genNine.png';
import './AirBnbRev.css';


const GenRev = () => {
 

  return (
    <div className="review-grid-container">
    
    <div className="image-review-container">
        <img className="review-img" src={arone} alt="Customer review"/>
        <img className="review-img" src={artwo} alt="Customer review"/>
        <img className="review-img" src={arthree} alt="Customer review"/>
        <img className="review-img" src={arfour} alt="Customer review"/>
        <img className="review-img" src={arfive} alt="Customer review"/>
        <img className="review-img" src={arsix} alt="Customer review"/>
        <img className="review-img" src={arseven} alt="Customer review"/>
        <img className="review-img" src={areight} alt="Customer review"/>
        <img className="review-img" src={arnine} alt="Customer review"/>
        
   </div> 
   
   
    </div>
  );
};

export default GenRev;