import React from 'react'
import carpetRoom from "../assets/Oven.png"
import "./SteamCleanTop.css";
import { Link } from 'react-router-dom';


const GeneralCleanThree = () => {
  return (
    
    <div className='carpet-wrapper carpet-wrapper-two'>
        <div className='carpet-description third-end-wrap'>
         <h2 className='not-sure rec-head'>Professional Home Cleaning Services in Melbourne</h2>
         <h4 className='not-sure rec-head'>Expert home cleaning near me</h4>
            <p className='p-description'>Are you dreading the hassle of cleaning your home? Sprucely Clean is here to help. We provide comprehensive home cleaning services to ensure your living space is clean and inviting. Imagine coming home to a spotless environment, where every corner has been meticulously cleaned.</p>
            <p className='p-description'>With our home cleaning services, you get peace of mind with our satisfaction guarantee, ensuring your home is cleaned to your exact specifications. Our team will work tirelessly to ensure your satisfaction with our cleaning results.</p>
            <p className='p-description'>Our home cleaning service is flexible and thorough, tailored to meet your specific needs and preferences. Whether you need a one-time deep clean or regular maintenance cleaning, we've got you covered.</p>
            <p className='p-description'>Booking our home cleaning service is fast and convenient, with flexible scheduling options available 7 days a week. We offer morning and afternoon appointments to accommodate your busy schedule.</p>
            <p className='p-description'>We provide a customizable cleaning checklist tailored to your home's needs. Our goal is to ensure every surface is sparkling clean and every room is fresh and inviting.</p>
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/General-Cleaning-Melbourne-quote"><button className='p-description end-lease-link end-three'>LIVE BOOKING AND PRICE</button></Link>
          </div>
        <div className='image-carpet oven-three'>
            <img className="carpet-room" src={carpetRoom} alt="Carpet that has been steamed clean"/>
        </div> 
    </div>
  )
}

export default GeneralCleanThree