import React, { useState, useEffect, useRef } from 'react';
import './Reviews.css';
import { RiStarSFill } from 'react-icons/ri';
import { MdOutlineVerified } from 'react-icons/md';


const reviewsData = [
{
id: 1,
name: "John H",
body: "Impressed with the service, always giving more then agreed",

city: "Pascoe vale VIC",
},
{
id: 2,
name: "Ava W",
body: "Exceeded our expectations with their high-quality work",

city: "Essendon Vic",
},
{
id: 3,
name: "Lucas L",
body: "My front yard looks amazing",

city: "Brunswick Vic",
},
{
id: 4,
name: "Sammy V",
body: "cleaned our home with their expertise and professionalism",

city: "Essendon Vic",
},
{
id: 5,
name: "Harvey L",
body: "Customer service was amazing, and backyard looks amazing",

city: "Melbourne Vic",
},
{
id: 6,
name: "Vivo T",
body: "Always book these guys for cleaning and lawns, no issues",
city: "Melbourne VIC",
},

// add more reviews here...
];




const Reviews = () => {
 const [reviews, setReviews] = useState([]);
 const [currentReview, setCurrentReview] = useState(0);
const reviewBackgroundRef = useRef(null);
const [isInView, setIsInView] = useState(false);

 useEffect(() => {
   if (reviewsData && reviewsData.length > 0) {
     setReviews(reviewsData);
   } else {
     console.error("Reviews data is missing or invalid!");
   }
 }, []);


 useEffect(() => {
   if (reviews.length > 0) {
     const interval = setInterval(() => {
       setCurrentReview((currentReview + 1) % reviews.length);
     }, 3000);
     return () => clearInterval(interval);
   }
 }, [currentReview, reviews.length]);


 useEffect(() => {
  const options = {
    rootMargin: '0px',
    threshold: 0.5,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsInView(true);
        observer.unobserve(entry.target);
      }
    });
  }, options);

  observer.observe(reviewBackgroundRef.current);

  return () => {
    observer.disconnect();
  };
}, []);


 


 return (
   <div className="reviews-container">
   
   <div className={`review__background ${isInView ? 'review__background--in-view' : ''}`} ref={reviewBackgroundRef}>
     {reviews.length > 0 && (
       <div className="review">
         <div className="reviewer">
         
           <div className="review__stars">
           <div className="name">{reviews[currentReview].name}</div>
           <div className="city">{reviews[currentReview].city}</div>
           <div className="stars">
           <RiStarSFill color="gold" />
           <RiStarSFill color="gold"/>
           <RiStarSFill color="gold"/>
           <RiStarSFill color="gold"/>
           <RiStarSFill color="gold"/>
           </div>
           <h5 className="Verified">Verified <MdOutlineVerified color="green"/></h5>
           </div>
         </div>
         <div className="comment">"{reviews[currentReview].body}"</div>
        
       </div>
     )}
     {reviews.length === 0 && (
       <div className="no-reviews">No reviews available.</div>
     )}
     </div>
   </div>
 );
};


export default Reviews;
