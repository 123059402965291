import React, { useState } from 'react';
import "./Faq.css"

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'How much does end of lease cleaning cost?',
      answer: 'The cost of end of lease cleaning can vary depending on the size of the property and specific requirements. Price ranges from $247 for a one bed, two bedroom $300, and three plus bedroom ranges from $400 to $800. Price for end of lease cleaning also depends on level of deep cleaning needed.'
    },
    {
      question: 'What counts towards wear and tear with end of lease?',
      answer: 'Normal wear and tear typically includes things like minor scuffs and marks that occur over time with normal use of the property. Damage beyond normal wear and tear may result in deductions from your bond.'
    },
    {
      question: 'What happens if the real estate agent is not happy with the end of lease cleaning?',
      answer: 'If the real estate agent is not satisfied with the cleaning, we will rectify any issues free of charge within a specified time frame within, as outlined in our terms and conditions.'
    },
    {
      question: 'Do you offer 100% bond back cleaning?',
      answer: 'Yes, we offer a bond back guarantee for our end of lease cleaning services, ensuring you receive your full bond refund, refund is based on end of lease cleaning offered (subject to terms and conditions).'
    },
    {
      question: 'How long does an end of lease clean take?',
      answer: 'The duration of an end of lease clean depends on the size and condition of the property. On average, it may take several hours to complete, two hours from smaller propertys and upto fours for larger propertys. We do not rush our end of lease cleaning service, we want to ensure the job is done right the first time.'
    },
    {
      question: 'Do I have to be at the property during the end of lease cleaning?',
      answer: 'No, you do not need to be present during the cleaning. We can arrange access to the property either through the real estate agent or via other arrangements.'
    },
    {
      question: 'Do you include laundry with Airbnb turnover cleans?',
      answer: 'Yes, laundry services are included as part of our Airbnb turnover cleaning package, offsite laundry if preferred and end up being a cheaper option for you.'
    },
    {
      question: 'Do you supply consumables for Airbnb cleans?',
      answer: 'Yes, we provide basic consumables such as toilet paper, soap, cleaning products is supplied, consumables are an addtional charge.'
    },
    {
      question: 'Will you return if a customer is unhappy with the cleaning from an Airbnb turnover?',
      answer: 'Yes, customer satisfaction is our priority. If you are not satisfied with the cleaning, we will return to address any issues promptly.'
    },
    {
      question: 'Do you offer dry cleaning of carpets?',
      answer: 'Yes, we offer dry cleaning services for carpets as an additional option.'
    },
    {
      question: 'How long does steam cleaning of carpets take?',
      answer: 'The duration of steam cleaning for carpets can vary depending on the size of the area being cleaned. On average, it may take 45 mins to a few hours to complete.'
    },
    {
      question: 'Do you have insurance and ABN?',
      answer: 'Yes, we are fully insured and hold a valid Australian Business Number (ABN), this is a requirement to get your bond back, it can also effect your insurance Premium hireing contractors with no insurance.'
    }
  ];

  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2>
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 className='q-head' onClick={() => toggleAnswer(index)}>{faq.question}</h3>
          {activeIndex === index && <p>{faq.answer}</p>}
        </div>
      ))}
    </div>
  );
}

export default FAQ;
