import React from 'react'
import carpetRoom from "../assets/whyUsPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css";
import logoCraig from "../assets/craigLogo.png"

const WhyUs = () => {
  return (
    <div className='carpet-wrapper why-us-container'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Nice looking styled kitchen"/>
        </div> 
        <div className='carpet-description'>
          <h6>Unlike some of our competition we dont need an onsite inspection to give you a final cleaning quote, with Sprucely you can build a instant quote with transparent pricing.</h6>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Transparent instant qoute</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>No onsite inspection needed for final quote</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>No hidden charges onsite</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Quote is priced for job completion</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Profesional equipment used</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Live instant booking</p>
            <p className='p-description-partners'>Proud partners</p>
            <img className="logo-agent" src={logoCraig} alt="logo of realestate agent"/>
      
        </div>
        
    </div>
  )
}

export default WhyUs