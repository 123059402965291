import React, { useState, useRef, useEffect, useCallback, useMemo} from "react";
import ReactGA from 'react-ga';
import emailjs from "@emailjs/browser";
import "./BookingClean.css";
import { Helmet } from "react-helmet"
import Header from "../components/Header";
import { BsListCheck } from 'react-icons/bs';
import { BiHomeSmile, BiCabinet  } from 'react-icons/bi';
import { BsFileEarmarkPerson } from 'react-icons/bs';
import { BiLocationPlus,  } from 'react-icons/bi';
import { TiTickOutline } from 'react-icons/ti';
import { TbFridge } from "react-icons/tb";
import { GiCookingGlove } from "react-icons/gi";
import { GiVacuumCleaner, GiSofa, GiRedCarpet,  } from 'react-icons/gi';

import TrippleIcons from "../components/TrippleIcons";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import icon from "../assets/iconG.png"
import { IoMdStar } from "react-icons/io";
import Profile from "../components/Profile";





// Define a component called Result to display a confirmation message

const Result = () => {
  return (
    <p className="result-msg">Our team will contact you shortly</p>
  );
};


// Define a component called PriceSection to calculate and display the price quote
const PriceSection = ({ 
  selectedBedroom, 
  selectedBathroom, 
  selectedKitchen, 
  selectedCleanliness, 
  selectedSteamClean, 
  selectedWindowClean, 
  selectedBalconyClean,
  selectedFridgeClean,
  selectedMicrowaveClean, 
  selectedDishClean, 
  selectedBlindClean, 
  selectedCabinetsClean,
  isDiscountApplied,
  handleDiscountClick,
  setFinalPrice }) => {
  

  //By wrapping the object initializations in useMemo with an empty dependency array, you ensure that these objects are only created once and won't cause the useCallback dependencies to change on every render.

  // Define prices for steam cleaning, window cleaning, and balcony cleaning
  const steamCleanPrices = useMemo(() => {
  return {
    "One seater or armchair": 35,
    "Two seater": 70,
    "Three seater": 95,
    "Four seater": 130,
    "Five seater": 160,
    "Six seater": 190,
   
  };
}, []);

// Wrap the initialization of windowCleanPrices in useMemo
const windowCleanPrices = useMemo(() => {
  return {
    "oven_stove_deep_clean": 60,
    "rangehood_deep_clean": 20,
    "deep_clean_ranghood_oven_stove": 95,
  };
}, []);

// Wrap the initialization of balconyCleanPrices in useMemo
const balconyCleanPrices = useMemo(() => {
  return {
    "single_door_fridge": 45,
    "double_door_fridge": 75,
    
  };
}, []);

// Wrap the initialization of fridgeCleanPrices in useMemo
const fridgeCleanPrices = useMemo(() => {
  return {
    "1 rug": 48, // Adjust the price as needed
    "2 rugs": 96,
    "3 rugs": 144,
  };
}, []);

const microwaveCleanPrices = useMemo(() => {
  return {
    "1_bedroom": 80, 
    "2_bedroom": 80,
    "3_bedroom": 80,
    "4_bedroom": 120,
    "5_bedroom": 160,
    "1_bedroom + living": 80,
    "2_bedroom + living": 120,
    "3_bedroom + living": 160,
    "4_bedroom + living": 200,
    "5_bedroom + living": 240,
  };
}, []);

const dishCleanPrices = useMemo(() => {
  return {
    "kitchen cabinets": 95, // Adjust the price as needed
    "Kitchen_bathroom_cabinets": 115,
    
  };
}, []);

const blindCleanPrices = useMemo(() => {
  return {
    "blinds_cleaned": 35, // Adjust the price as needed
  };
}, []);

const cabinetsCleanPrices = useMemo(() => {
  return {
    "kitchen_cabinets": 10, // Adjust the price as needed
    "bathroom_cabinets": 10,
    "wardrobe_cabinets": 5,
    "all_cabinets": 30,


  };
}, []);


  
   // Function to calculate the final price based on selected options
   const calculatePrice = useCallback(() => {
    

    // Define a list of price options for different combinations
    const priceOptions = [
      { bedrooms:  1, bathrooms: 1, kitchens: 1, price: 139 },
      { bedrooms: 1, bathrooms: 2, kitchens: 1, price: 160 },
      { bedrooms: 2, bathrooms: 1, kitchens: 1, price: 150 },
      { bedrooms: 2, bathrooms: 2, kitchens: 1, price: 172 },
      { bedrooms: 3, bathrooms: 1, kitchens: 1, price: 193 },
      { bedrooms: 3, bathrooms: 2, kitchens: 1, price: 218 },
      { bedrooms: 4, bathrooms: 2, kitchens: 1, price: 246 },
    ];

    // Find the selected option based on the number of bedrooms, bathrooms, and kitchens
    const selectedOption = priceOptions.find(option =>
      option.bedrooms === selectedBedroom &&
      option.bathrooms === selectedBathroom &&
      option.kitchens === selectedKitchen
    );

    // Calculate prices for additional services
      const steamCleanPrice = steamCleanPrices[selectedSteamClean] || 0;
      const windowCleanPrice = windowCleanPrices[selectedWindowClean] || 0;
      const balconyCleanPrice = balconyCleanPrices[selectedBalconyClean] || 0;
      const fridgeCleanPrice = fridgeCleanPrices[selectedFridgeClean] || 0;
      const microwaveCleanPrice = microwaveCleanPrices[selectedMicrowaveClean] || 0;
      const dishCleanPrice = dishCleanPrices[selectedDishClean] || 0;
      const blindCleanPrice = blindCleanPrices[selectedBlindClean] || 0;
      const cabinetsCleanPrice = cabinetsCleanPrices[selectedCabinetsClean] || 0;

      
    
       // Apply a 10% discount if isDiscountApplied is true
   
  
  
    // Calculate the final price
    let finalPrice = selectedOption ? selectedOption.price : 0;



    // Apply a 10% discount if isDiscountApplied is true
     if (isDiscountApplied) {
      finalPrice *= 0.8; // Apply a 10% discount
    }

    finalPrice = Math.floor(finalPrice);
      


    if (selectedCleanliness === "medium_deep_clean") {
      finalPrice += 50;
    } else if (selectedCleanliness === "heavy_deep_clean") {
      finalPrice += 100;
    }

    finalPrice += steamCleanPrice;
    finalPrice += windowCleanPrice;
    finalPrice += balconyCleanPrice;
    finalPrice += fridgeCleanPrice;
    finalPrice += microwaveCleanPrice;
    finalPrice += dishCleanPrice;
    finalPrice += blindCleanPrice;
    finalPrice += cabinetsCleanPrice;

    

    return finalPrice;
  }, [
    selectedBedroom,
    selectedBathroom,
    selectedKitchen,
    selectedCleanliness,
    selectedSteamClean,
    selectedWindowClean,
    selectedBalconyClean,
    selectedFridgeClean,
    selectedMicrowaveClean,
    selectedDishClean,
    selectedBlindClean,
    selectedCabinetsClean,
    cabinetsCleanPrices,
    blindCleanPrices,
    dishCleanPrices,
    microwaveCleanPrices,
    balconyCleanPrices,
    windowCleanPrices,
    steamCleanPrices,
    fridgeCleanPrices,
    isDiscountApplied,
    
  ]);

   // Calculate the price
    const price = calculatePrice();

   // Check if the user has selected any options
   const hasSelectedOptions = selectedBedroom !== 0 && selectedBathroom !== 0 && selectedKitchen !== 0;
   

   // Define cleaning checklists for different areas
   const bedroomCleaningChecklist = [
      "Dust all surfaces",
      "Vacuum and mop floors",
      "Clean mirrors + wardrobe",
      "Dust skirtings",
      "Spot clean wall dirt marks",
     
    ];

    const bathroomCleaningChecklist = [
      "Clean toilet and sink.",
      "Clean shower and bath",
      "Wipe down mirrors and any glass",
      "Dust shelves and counters",
      "Mop or sweep the floor",
      "Clean cabinets (outside)",
      "Spot clean walls",
    ];

    const kitchenCleaningChecklist = [
      "Clean countertop and sink",
      "Wipe oven inside & out",
      "Wipe range hood exterior",
      "Wipe down all cabinets outside",
      "Mop or sweep the floors",
      "Dust skirtings",
      "Spot clean walls",
      "Wipe down table chairs",
    ];

    const livingCleaningChecklist = [
      "Dust all surfaces",
      "Vacuum, sweep or mop floors",
      "Dust skirtings",
      "Spot clean walls",
      "Wipe outside cabinets",
    ];

    const laundryCleaningChecklist = [
      "Dust all surfaces",
      "Vacuum, sweep or mop floors",
      "Dust skirtings",
      "Spot clean walls",
      "Wipe outside cabinets",
      
    ];

    

  // useEffect to pass onto hidden input for email.js

  useEffect(() => {
    const price = calculatePrice();

    // Update the finalPrice in the parent component
   setFinalPrice(price);
}, [
  selectedBedroom,
  selectedBathroom,
  selectedKitchen,
  selectedCleanliness,
  selectedSteamClean,
  selectedWindowClean,
  selectedBalconyClean,
  selectedFridgeClean,
  selectedMicrowaveClean,
  selectedDishClean,
  selectedBlindClean,
  selectedCabinetsClean,
  setFinalPrice,
  calculatePrice, // Include calculatePrice in the dependency array
]);


// Render the price section with selected options and checklists
  return (
    <div className="price-section">
     

    
      <h4 className="price-heading">Quote Price:</h4>

      <div className="white-box">
       <p className="selected-option agent-clean"><BsListCheck color="#3AAFA9" size={25}/><span className="agent-heading agent-color">Whats included?</span></p>
       
       
       <hr />
        <div>
          <p className="selected-option price">Bedrooms: {selectedBedroom}</p>
          <ul>
            {bedroomCleaningChecklist.map((item, index) => (
                  <li className="li-check-items" key={index}><TiTickOutline/>{item}</li>
            ))}
          </ul>
        </div>

        {selectedBathroom && (
        <>
        <p className="selected-option price">Bathrooms: {selectedBathroom}</p>
        <ul>
          {bathroomCleaningChecklist.map((item, index) => (
            <li className="li-check-items" key={index}>
              <TiTickOutline />
              {item}
            </li>
                  ))}
                </ul>
          </>
        )}

        {selectedKitchen && (
        <>
          <p className="selected-option price">Kitchens: {selectedKitchen}</p>
          <ul>
          {kitchenCleaningChecklist.map((item, index) => (
            <li className="li-check-items" key={index}>
              <TiTickOutline />
              {item}
            </li>
                  ))}
                </ul>
                </>
        )}
          <>
          <p className="selected-option price">Living area: 1</p>
          <ul>
          {livingCleaningChecklist.map((item, index) => (
            <li className="li-check-items" key={index}>
              <TiTickOutline />
              {item}
            </li>
                  ))}
                </ul>
                </>

                <>
                  <p className="selected-option price">Laundry area: 1</p>
                  <ul>
                  {laundryCleaningChecklist.map((item, index) => (
                    <li className="li-check-items" key={index}>
                      <TiTickOutline />
                      {item}
                    </li>
                  ))}
                </ul>
                </>


                

                <hr />
        <p className="selected-option price">EXTRAS:</p>
        {selectedSteamClean && (
        <p className="selected-option">Sofa steam clean: {selectedSteamClean.replace("_", " ")} (${steamCleanPrices[selectedSteamClean]})</p>
      )}
      {selectedWindowClean && (
          <p className="selected-option">Oven + Stove + rangehood: {selectedWindowClean.replace("_", " ")} (${windowCleanPrices[selectedWindowClean]})</p>
        )}
        {selectedBalconyClean && (
          <p className="selected-option">Deep clean fridge: {selectedBalconyClean.replace("_", " ")} (${balconyCleanPrices[selectedBalconyClean]})</p>
        )}
        {selectedFridgeClean && ( // Include the selected fridge cleaning option
          <p className="selected-option">Rug Steam Clean: {selectedFridgeClean.replace("_", " ")} (${fridgeCleanPrices[selectedFridgeClean]})</p>
        )}
        {selectedMicrowaveClean && ( // Include the selected fridge cleaning option
          <p className="selected-option">Carpet Steam Clean: {selectedMicrowaveClean.replace("_", " ")} (${microwaveCleanPrices[selectedMicrowaveClean]})</p>
        )}
        {selectedDishClean && ( // Include the selected fridge cleaning option
          <p className="selected-option">Internal clean cabinets: {selectedDishClean.replace("_", " ")} (${dishCleanPrices[selectedDishClean]})</p>
        )}
        

        
        <hr />
        {hasSelectedOptions ? (
          <p className="price"> TOTAL <span className="color-price">${price}</span></p>
        ) : (
          <p className="price">Please select options</p>
        )}

        {/*Discount render*/}
        <div>
          <div className="discount-box" onClick={handleDiscountClick}>
         {isDiscountApplied ? (
          <p className="dicount-p">Discount Applied (Click to Remove)</p>
        ) : (
          <button className="discount-button">Apply 10% Discount</button>
        )}
      </div>
    </div>
    
        <p className="price book-later fixed-serv">Clean includes the above check list <br/>with a fixed price service  and<br/> no addtional hourly rates.</p>

        <p className="price book-later">Send booking request and our team will contact you shortly to confirm<br/> booking.</p>
      </div>
    </div>
  );
};

// Define the BookingClean component
const BookingGeneralQuote = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Google Tag for remarketing and conversion tracking
    window.gtag('config', 'AW-11025934865');
  }, []);

  useEffect(() => {
  ReactGA.initialize('G-ECHQXBFYLM'); 
  // Report page view
  ReactGA.pageview(window.location.pathname + window.location.search);
}, []);
useEffect(() => {
   console.log(window.location.pathname)
  })
  const [finalPrice, setFinalPrice] = useState(0);
  //State variable for discount
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);

  // Function to handle the discount box click
  const handleDiscountClick = () => {
    setIsDiscountApplied(!isDiscountApplied); // Step 2: Toggle the discount state
  };



  useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []);



  const FormSubmission = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Form submission button clicked'
    });
  };

  // Define state variables for form inputs and result display

  const [result, showResult] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState("");
  const [selectedBedroom, setSelectedBedroom] = useState("1");
  const [selectedBathroom, setSelectedBathroom] = useState("1");
  const [selectedKitchen, setSelectedKitchen] = useState("1");
  const [selectedCleanliness, setSelectedCleanliness] = useState("standard_clean");
  const [selectedSteamClean, setSelectedSteamClean] = useState("");
  const [selectedWindowClean, setSelectedWindowClean] = useState("");
  const [selectedBalconyClean, setSelectedBalconyClean] = useState("");
  const [selectedFridgeClean, setSelectedFridgeClean] = useState("");
  const [selectedMicrowaveClean, setSelectedMicrowaveClean] = useState("");
  const [selectedDishClean, setSelectedDishClean] = useState("");
  const [selectedBlindClean, setSelectedBlindClean] = useState("");
  const [selectedCabinetsClean, setSelectedCabinetsClean] = useState("");
  

  
  

  const form = useRef(null);

   // Function to send an email and display a confirmation message

  const sendEmail = (e) => {
    e.preventDefault();

    const selectedExtras = {
          selectedSteamClean,
          selectedWindowClean,
          selectedBalconyClean,
          selectedFridgeClean,
          selectedMicrowaveClean,
          selectedDishClean,
          selectedBlindClean,
          selectedCabinetsClean
        };

        // Include the selected extras in the email template
        const emailParams = {
          // Other email parameters...
          selectedExtras: JSON.stringify(selectedExtras),
        };

    
    emailjs
      .sendForm("service_k4aiwmr", "template_lw174aa", form.current, "Fh0Y9YM-DloiKhGif", emailParams)
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    showResult(true);

    navigate("/Thanks");
  };


  // Render the BookingClean component

  return (
    <div>
    <Helmet>
          <meta name="description" content="We offer general and end of lease cleaning service."/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11025934865"></script>
        </Helmet>
      <Header/>
       <div className="icon-wrap-proccess">
        <TrippleIcons/>
      </div>
      <div className="form-clean-book">
        
        <h6 className="clean-heading">Book your General clean</h6>
        <h2 className="clean-h2">*select a service, choose a date & pay later</h2>

        <form className="form-clean" ref={form} onSubmit={sendEmail}>
        <div className="flex-book-container">
          <div className="form-one">
          <p className="price_info"></p>
            <div className="form-container-clean">
            <div className="icon-heading-container">
                    <BsFileEarmarkPerson size={25} />
                    <h4 className="clean-h4">WHO YOU ARE</h4>
                  </div>
           
              <p className="clean-p">This information will be used to contact<br /> you about your service.</p>

              <input className="input-contact-clean" type="text" name="user_name" placeholder="Name" required />
              <input className="input-contact-clean" type="email" name="user_email" placeholder="Email" required />
              <input className="input-contact-clean" type="text" name="user_mobile" placeholder="Mobile" required />

              <div className="address-section">
              <div className="icon-heading-container">
                    <BiLocationPlus size={25} />
                    <h4 className="clean-h4">ADDRESS</h4>
                  </div>
             
                <p className="clean-p">Where would you like us to clean?</p>
                <textarea className="input-contact-clean" type="text" name="address" placeholder="Address" required />
                <textarea className="input-contact-clean" type="text" name="suburb" placeholder="Suburb" required />
              </div>

                <div className="icon-heading-container">
                    <BiHomeSmile size={25} />
                    <h4 className="clean-h4">TELL US ABOUT YOUR HOME</h4>
                  </div>
              <div className="tell-us-service">
                <select className="input-contact-clean input-select-width" name="bedroom" onChange={(e) => setSelectedBedroom(e.target.value)} required>
                  <option value="1" >1 bedroom</option>
                  <option value="2">2 bedrooms</option>
                  <option value="3">3 bedrooms</option>
                  <option value="4">4 bedrooms</option>
                  <option value="5">5 bedrooms</option>
                </select>

                <select className="input-contact-clean input-select-width" name="bathroom" onChange={(e) => setSelectedBathroom(e.target.value)} required>
                
                  <option value="1">1 bathroom</option>
                  <option value="2">2 bathrooms</option>
                  <option value="3">3 bathrooms</option>
                  <option value="4">4 bathrooms</option>
                  <option value="5">5 bathrooms</option>
                </select>

                <select className="input-contact-clean input-select-width" name="kitchen" onChange={(e) => setSelectedKitchen(e.target.value)} required>
                 
                  <option value="1">1 kitchen</option>
                  <option value="2">2 kitchens</option>
                  <option value="3">3 kitchens</option>
                </select>

                <h6 className="extra-icons">Heavy deep clean needed?</h6>
                <h8 className="rec-deep">(Medium to heavy deep clean<br/>recommended for first time clean)</h8>
                <select className="input-contact-clean input-select-width" name="cleanliness" onChange={(e) => setSelectedCleanliness(e.target.value)} required>
                  
                  <option value="standard_clean">Just standard general clean</option>
                  <option value="medium_deep_clean">Needs a once off medium deep clean</option>
                  <option value="heavy_deep_clean">Needs a once off heavy deep clean</option>
                </select>

             

                 <div className="icon-heading-container"> 
                    <h4 className="clean-h4 add-extras">OPTIONAL EXTRAS</h4>
                  </div>

                  <h6 className="extra-icons"><GiCookingGlove size={25}/>Oven + stove + rangehood</h6>
                <select
                  className="input-contact-clean input-select-width input-extras-width"
                  name="window_clean" // Change the name attribute to something appropriate
                  onChange={(e) => setSelectedWindowClean(e.target.value)}
                  
                    >
                  <option value="">Oven, stove, rangehood?</option>
                  <option value="oven_stove_deep_clean">Deep clean Oven + stove</option>
                  <option value="rangehood_deep_clean">Deep clean rangehood</option>
                  <option value="deep_clean_ranghood_oven_stove">Deep clean all</option>
                </select>

                <h6 className="extra-icons"><TbFridge size={25}/>Fridge  clean</h6>
                <select
                  className="input-contact-clean input-select-width input-extras-width"
                  name="balcony_clean" // Change the name attribute to something appropriate
                  onChange={(e) => setSelectedBalconyClean(e.target.value)}
              
                    >
                  <option value="">Fridge clean?</option>
                  <option value="single_door_fridge">Single door fridge clean</option>
                  <option value="double_door_fridge">Double door fridge clean</option>
                  
                </select>


                 <h6 className="extra-icons"><BiCabinet size={25}/>Inside cabinets</h6>
                 <select
                    className="input-contact-clean input-select-width input-extras-width"
                    name="Laundry"
                    onChange={(e) => setSelectedDishClean(e.target.value)}
                  >
                    <option value="">Clean inside cabinets?</option>
                    <option value="kitchen cabinets">Inside kitchen cabinets</option>
                    <option value="Kitchen_bathroom_cabinets">Inside kitchen + bathroom cabinets</option>
                </select>


                 <h6 className="extra-icons"><GiVacuumCleaner size={25}/>Carpet steam clean</h6>
                <select
                    className="input-contact-clean input-select-width input-extras-width"
                    name="microwave_clean"
                    onChange={(e) => setSelectedMicrowaveClean(e.target.value)}
                  >
                    <option value="">Carpet steam clean?</option>
                    <option value="1_bedroom">One Bedroom</option>
                    <option value="2_bedroom">Two Bedroom</option>
                    <option value="3_bedroom">Three Bedroom</option>
                    <option value="4_bedroom">Four Bedroom</option>
                    <option value="5_bedroom">Five Bedroom</option>
                    <option value="1_bedroom + living">One Bedroom + Living</option>
                    <option value="2_bedroom + living">Two Bedroom + Living</option>
                    <option value="3_bedroom + living">Three Bedroom + Living</option>
                    <option value="4_bedroom + living">Four Bedroom + Living</option>
                    <option value="5_bedroom + living">Five Bedroom + Living</option>
             
                </select>

                <h6 className="extra-icons"><GiSofa size={25}/> Sofa steam clean</h6>
                <select className="input-contact-clean input-select-width input-extras-width" name="steam_clean" onChange={(e) => setSelectedSteamClean(e.target.value)} >
                  <option value="">Need sofa steam clean?</option>
                  <option value="One seater or armchair">1 seater</option>
                  <option value="Two seater">2 seater</option>
                  <option value="Three seater">3 seater</option>
                  <option value="Four seater">4 seater</option>
                  <option value="Five seater">5 seater</option>
                  <option value="Six seater">6 seater</option>

                </select>

                <h6 className="extra-icons"><GiRedCarpet size={25}/>Rug steam clean </h6>
                <select
                    className="input-contact-clean input-select-width input-extras-width"
                    name="fridge_clean"
                    onChange={(e) => setSelectedFridgeClean(e.target.value)}
                  >
                    <option value="">Rug steam clean?</option>
                    <option value="1 rug">One rug steam clean</option>
                    <option value="2 rugs">Two rugs steam cleaned</option>
                    <option value="3 rugs">Three rugs steam cleaned</option> 
                </select>

                
              </div>

              <div className="book-clean">
                <h4 className="clean-h4">WHEN WOULD YOU LIKE US TO COME?</h4>
                <p className="clean-p">Choose the date and arrival window*<br /> that works for you. If you need a <br />last-minute appointment give<br /> us a call at 0433310964</p>
                <input
                  className="input-contact-clean"
                  type="datetime-local"
                  name="clean_date"
                  onChange={(e) => setSelectedDateTime(e.target.value)}
                  required
                />
              </div>
              <h4 className="clean-h4">Comments & Special Instructions</h4>
              <textarea className="input-contact-clean" type="text" name="Instructions" placeholder="Is there anything we should know? "  />
              
            </div>
          </div>
          
    
     <div className="section-price-div">
          <PriceSection
            selectedBedroom={parseInt(selectedBedroom)}
            selectedBathroom={parseInt(selectedBathroom)}
            selectedKitchen={parseInt(selectedKitchen)}
            selectedCleanliness={selectedCleanliness}
            selectedSteamClean={selectedSteamClean} 
            selectedWindowClean={selectedWindowClean}
            selectedBalconyClean={selectedBalconyClean}
            selectedFridgeClean={selectedFridgeClean} 
            selectedMicrowaveClean={selectedMicrowaveClean}
            selectedDishClean={selectedDishClean}
            selectedBlindClean={selectedBlindClean}
            selectedCabinetsClean={selectedCabinetsClean}
            setFinalPrice={setFinalPrice}
            isDiscountApplied={isDiscountApplied}
            handleDiscountClick={handleDiscountClick}// Pass handleDiscountClick as a prop
          />
      </div> 

      </div>  
          <div> {result ? <Result /> : null}</div>
          
          <input onClick={FormSubmission} className="qoute-button" type="submit" value="Send booking request" />
          {/*Hidden imput for emailjs*/}
            <input type="hidden" name="selectedSteamClean" value={selectedSteamClean} />
            <input type="hidden" name="selectedWindowClean" value={selectedWindowClean} />
            <input type="hidden" name="selectedBalconyClean" value={selectedBalconyClean} />
            <input type="hidden" name="selectedFridgeClean" value={selectedFridgeClean}/>
            <input type="hidden" name="selectedMicrowaveClean" value={selectedMicrowaveClean}/>
            <input type="hidden" name="selectedBlindClean" value={selectedBlindClean}/>
            <input type="hidden" name="selectedDishClean" value={selectedDishClean}/>
            <input type="hidden" name="selectedCabinetsClean" value={selectedCabinetsClean}/>
           <input type="hidden" name="final_price" value={finalPrice} />

           <div className="review-bottom">
            <div className="review-content-bottom">
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <img className="gimage" src={icon} alt="logo for customer rating"/>
           </div>

           </div>
            
        </form>

       <div className="pro-marg">
        <Profile/>
       </div> 
        <Footer/>
      </div>  
      
    </div>
  );
};

export default BookingGeneralQuote;



