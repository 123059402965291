import React, { useState, useEffect } from 'react';
import './WorkSlides.css';
import workOne from "../assets/moreone.png"
import workTwo from '../assets/moretwo.png'
import workThree from "../assets/morethree.png"
import workFour from "../assets/morefour.png"
import workFive from "../assets/morefive.png"
import workSix from "../assets/moresix.png"
import { AiFillStar } from 'react-icons/ai';


const images = [
  workOne,
  workTwo,
  workThree,
  workFour,
  workFive,
  workSix,
 
 

  
  // Add other image names here
];

const reviews = [
  {
    text: "Shaun did an amazing job with my Airbnb cleaning. The place looks fantastic!" ,
    rating: 5, // 5 stars
    name:"Juno.I"
  },
  {
    text: "Thanks to Shaun, my Bathroom looks amazing!",
    rating: 5, // 5 stars
    name:"Anne.L"
  },
  {
    text: "Deep cleaned my kitchen, exceeding my expectations. Highly recommend!",
    rating: 5, // 3 stars
    name:"Cassie.M"
  },
  {
    text: "Shaun's cleaning service transformed my Kitchen. Couldn't be happier with the results!",
    rating: 5, // 3 stars
    name:"Viv.T"
  },
  {
    text: "Great team to work with, useing ongoing",
    rating: 5, // 3 stars
    name:"Joseph.H"
  },

  {
    text: "Never in issues relating to cleaning, always on point",
    rating: 5, // 3 stars
    name:"Caius.K"
  },

  
  // Add more review objects as needed
];

const WorkSlidesRevGen = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change slide every 3 seconds (3000 milliseconds)

    return () => {
      clearInterval(interval);
    };
  }, []); // Empty dependency array ensures effect runs once after initial render


  const reviewIndex = currentIndex % reviews.length;
  const reviewData = reviews[reviewIndex];
  const reviewRating = reviewData ? reviewData.rating : 0;

  return (
    <div>
    <p className='testimonail'>"Satisfied Customers"<br/><span className='melb-across'>Across the Melbourne area</span></p>
    <div className="work-slides-container">
    
      <img className="work-images" src={`${images[currentIndex]}`} alt={`work ${currentIndex + 1}`} />
      <div className="review-container">
      <p className='reviews-p'>" {reviewData.text} "</p>
      <div className='rating-work'>
      {[...Array(reviewRating)].map((_, index) => (
              <AiFillStar key={index} size={30} color='gold' />
            ))}
      </div>
      <p className='name-rate'>{reviewData.name}</p>
      </div>
    </div>
    
   </div> 
  );
};

export default WorkSlidesRevGen;
