import React, { useState, useRef, useEffect, useCallback, useMemo} from "react";
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet"
import emailjs from "@emailjs/browser";
import "./BookingClean.css";
import Header from "../components/Header";
import { BsListCheck } from 'react-icons/bs';
import { BiHomeSmile } from 'react-icons/bi';
import { BsFileEarmarkPerson } from 'react-icons/bs';
import { BiLocationPlus } from 'react-icons/bi';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { TiTickOutline } from 'react-icons/ti';
import { MdOutlineCleaningServices } from "react-icons/md"
import EndOfLeaseCleanTop from "../components/EndOfLeaseCleanTop";
import rating from "../assets/ratingPic.png"
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import TrippleIcons from "../components/TrippleIcons";
import icon from "../assets/iconG.png"
import { IoMdStar } from "react-icons/io";
import VideoPlayer from "../components/VideoPlayerClean";
import Profile from "../components/Profile";





// Define a component called Result to display a confirmation message

const Result = () => {
  return (
    <p className="result-msg">Our team will contact you shortly</p>
  );
};


// Define a component called PriceSection to calculate and display the price quote
const PriceSection = ({ 
  selectedBedroom, 
  selectedBathroom, 
  selectedKitchen, 
  selectedLaundry,
  selectedCleanliness, 
  selectedSteamClean, 
  selectedWindowClean, 
  selectedBalconyClean, 
  isDiscountApplied,
  handleDiscountClick,
  setFinalPrice }) => {
  

  //By wrapping the object initializations in useMemo with an empty dependency array, you ensure that these objects are only created once and won't cause the useCallback dependencies to change on every render.

  // Define prices for steam cleaning, window cleaning, and balcony cleaning
  const steamCleanPrices = useMemo(() => {
  return {
    "1_bedroom": 70,
    "2_bedroom": 70,
    "3_bedroom": 70,
    "4_bedroom": 110,
    "5_bedroom": 150,
    "1_bedroom + living": 70,
    "2_bedroom + living": 110,
    "3_bedroom + living": 150,
    "4_bedroom + living": 190,
    "5_bedroom + living": 230,
  };
}, []);

// Wrap the initialization of windowCleanPrices in useMemo
const windowCleanPrices = useMemo(() => {
  return {
    "two_windows": 30,
    "four_windows": 80,
    "six_plus_windows": 200,
  };
}, []);

// Wrap the initialization of balconyCleanPrices in useMemo
const balconyCleanPrices = useMemo(() => {
  return {
    "small_balcony": 60,
    "medium_balcony": 80,
    "large_balcony": 120,
  };
}, []);
  
  
   // Function to calculate the final price based on selected options
   const calculatePrice = useCallback(() => {
    

    // Define a list of price options for different combinations
    const priceOptions = [
      { bedrooms:  1, bathrooms: 1, kitchens: 1, Laundry: 1, price: 275 },
      { bedrooms: 1, bathrooms: 2, kitchens: 1, Laundry: 1, price: 320 },
      { bedrooms: 2, bathrooms: 1, kitchens: 1, Laundry: 1, price: 319 },
      { bedrooms: 2, bathrooms: 2, kitchens: 1, Laundry: 1, price: 385 },
      { bedrooms: 3, bathrooms: 1, kitchens: 1, Laundry: 1, price: 445 },
      { bedrooms: 3, bathrooms: 2, kitchens: 1, Laundry: 1, price: 500 },
      { bedrooms: 3, bathrooms: 3, kitchens: 1, Laundry: 1, price: 600 },
      { bedrooms: 4, bathrooms: 1, kitchens: 1, Laundry: 1, price: 500 },
      { bedrooms: 4, bathrooms: 2, kitchens: 1, Laundry: 1, price: 550 },
      { bedrooms: 4, bathrooms: 3, kitchens: 1, Laundry: 1, price: 650 },
      { bedrooms: 5, bathrooms: 1, kitchens: 1, Laundry: 1, price: 650 },
      { bedrooms: 5, bathrooms: 2, kitchens: 1, Laundry: 1, price: 700 },
      { bedrooms: 5, bathrooms: 3, kitchens: 1, Laundry: 1, price: 750 },

    ];

    // Find the selected option based on the number of bedrooms, bathrooms, and kitchens
    const selectedOption = priceOptions.find(option =>
      option.bedrooms === selectedBedroom &&
      option.bathrooms === selectedBathroom &&
      option.kitchens === selectedKitchen &&
      option.Laundry === selectedLaundry
      
    );

    // Calculate prices for additional services
      const steamCleanPrice = steamCleanPrices[selectedSteamClean] || 0;
      const windowCleanPrice = windowCleanPrices[selectedWindowClean] || 0;
      const balconyCleanPrice = balconyCleanPrices[selectedBalconyClean] || 0;

  
  
    // Calculate the final price
    let finalPrice = selectedOption ? selectedOption.price : 0;

    // Apply a 10% discount if isDiscountApplied is true
     if (isDiscountApplied) {
      finalPrice *= 0.9; // Apply a 10% discount
    }

    if (selectedCleanliness === "medium_deep_clean") {
      finalPrice += 60;
    } else if (selectedCleanliness === "heavy_deep_clean") {
      finalPrice += 120;
    }

    finalPrice += steamCleanPrice;
    finalPrice += windowCleanPrice;
    finalPrice += balconyCleanPrice;

    return finalPrice;
  }, [ selectedBedroom,
    selectedBathroom,
    selectedKitchen,
    selectedCleanliness,
    selectedSteamClean,
    selectedWindowClean,
    selectedBalconyClean,
    balconyCleanPrices,
    windowCleanPrices,
    isDiscountApplied,
    steamCleanPrices
    
  ]);

   // Calculate the price
    const price = calculatePrice();

   // Check if the user has selected any options
   const hasSelectedOptions = selectedBedroom !== 0 && selectedBathroom !== 0 && selectedKitchen !== 0;
   

   // Define cleaning checklists for different areas
   const bedroomCleaningChecklist = [
      "Dust all surfaces",
      "Vacuum and mop floors",
      "Clean mirrors + wardrobe",
      "Clean internal windows",
      "Clean skirtings",
      "Spot clean walls",
      "Internal window frames",
      "Doors,fixtures + fittings"
    ];

    const bathroomCleaningChecklist = [
      "Clean toilet and sink.",
      "Clean shower screen.",
      "Clean shower and bath",
      "Wipe down mirrors and any glass",
      "Dust shelves and counters",
      "Mop or sweep the floor",
      "Clean inside cabinets",
      "Spot clean walls",
      "Internal windows + frames",
      "Doors,fixtures + fittings"
    ];

    const kitchenCleaningChecklist = [
      "Clean countertop and sink",
      "Clean stovetop and oven",
      "Clean spash-back",
      "Clean range hood",
      "Wipe down cabinets and drawers",
      "Clean inside cabinets",
      "Mop or sweep the floors",
      "Clean skirtings",
      "Mop or sweep the floor",
      "Spot clean walls",
      "Internal windows + frames",
      "Doors,fixtures + fittings"
    ];

    const livingCleaningChecklist = [
      "Dust all surfaces",
      "Vacuum, sweep or mop floors",
      "Internal windows + frames",
      "Clean skirtings",
      "Spot clean walls",
      "Clean aircon filter",
      "Doors,fixtures + fittings"
    ];


     const laundryCleaningChecklist = [
      "Dust all surfaces",
      "Vacuum, sweep or mop floors",
      "Internal windows + frames",
      "Clean skirtings",
      "Spot clean walls",
      "Clean tub and tabs",
      "Doors,fixtures + fittings"
    ];

    

  // useEffect to pass onto hidden input for email.js

  useEffect(() => {
    const price = calculatePrice();

    // Update the finalPrice in the parent component
   setFinalPrice(price);
}, [
  selectedBedroom,
  selectedBathroom,
  selectedKitchen,
  selectedCleanliness,
  selectedSteamClean,
  selectedWindowClean,
  selectedBalconyClean,
  setFinalPrice,
  calculatePrice, // Include calculatePrice in the dependency array
]);


// Render the price section with selected options and checklists
  return (
    <div className="price-section">

    
      <h4 className="price-heading">Quote Price:</h4>
      <div className="white-box">
       <p className="selected-option agent-clean"><BsListCheck color="#3AAFA9" size={25}/><span className="agent-heading agent-color">Agent End of lease clean</span></p>
       
       
       <hr />
        <div>
          <p className="selected-option price">Bedrooms: {selectedBedroom}</p>
          <ul>
            {bedroomCleaningChecklist.map((item, index) => (
                  <li className="li-check-items" key={index}><TiTickOutline/>{item}</li>
            ))}
          </ul>
        </div>

        {selectedBathroom && (
        <>
        <p className="selected-option price">Bathrooms: {selectedBathroom}</p>
        <ul>
          {bathroomCleaningChecklist.map((item, index) => (
            <li className="li-check-items" key={index}>
              <TiTickOutline />
              {item}
            </li>
                  ))}
                </ul>
          </>
        )}

        {selectedKitchen && (
        <>
          <p className="selected-option price">Kitchens: {selectedKitchen}</p>
          <ul>
          {kitchenCleaningChecklist.map((item, index) => (
            <li className="li-check-items" key={index}>
              <TiTickOutline />
              {item}
            </li>
                  ))}
                </ul>
                </>
        )}
          <>
          {selectedKitchen && (
        <>
          <p className="selected-option price">Laundry: {selectedLaundry}</p>
          <ul>
          {laundryCleaningChecklist.map((item, index) => (
            <li className="li-check-items" key={index}>
              <TiTickOutline />
              {item}
            </li>
                  ))}
                </ul>
                </>
        )}
          <></>
          <p className="selected-option price">Living area: 1</p>
          <ul>
          {livingCleaningChecklist.map((item, index) => (
            <li className="li-check-items" key={index}>
              <TiTickOutline />
              {item}
            </li>
                  ))}
                </ul>
                </>

                <hr />
        <p className="selected-option price">EXTRAS:</p>
        {selectedSteamClean && (
        <p className="selected-option">Steam Clean: {selectedSteamClean.replace("_", " ")} (${steamCleanPrices[selectedSteamClean]})</p>
      )}
      {selectedWindowClean && (
          <p className="selected-option">Window Cleaning: {selectedWindowClean.replace("_", " ")} (${windowCleanPrices[selectedWindowClean]})</p>
        )}
        {selectedBalconyClean && (
          <p className="selected-option">Balcony Clean: {selectedBalconyClean.replace("_", " ")} (${balconyCleanPrices[selectedBalconyClean]})</p>
        )}
        
        <hr />
        {hasSelectedOptions ? (
          <p className="price"> TOTAL <span className="color-price">${price}</span></p>
        ) : (
          <p className="price">Please select options</p>
        )}

         {/*Discount render*/}
        <div>
              <div className="discount-box" onClick={handleDiscountClick}>
            {isDiscountApplied ? (
              <p className="dicount-p">Discount Applied (Click to Remove)</p>
            ) : (
              <button className="discount-button">Apply 10% Discount</button>
            )}
          </div>
      </div>

        <p className="price book-later">Send booking request and our team will contact you shortly to confirm<br/> booking.</p>
      </div>
    </div>
  );
};

// Define the BookingClean component
const BookingClean = () => {
  const navigate = useNavigate();
  

   useEffect(() => {
    // Google Tag for remarketing and conversion tracking
    window.gtag('config', 'AW-11025934865');
  }, []);

  useEffect(() => {
  ReactGA.initialize('G-ECHQXBFYLM'); 
  // Report page view
  ReactGA.pageview(window.location.pathname + window.location.search);
}, []);
useEffect(() => {
   console.log(window.location.pathname)
  })


  const [finalPrice, setFinalPrice] = useState(0);
  //State variable for discount
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);

  // Function to handle the discount box click
  const handleDiscountClick = () => {
    setIsDiscountApplied(!isDiscountApplied); // Step 2: Toggle the discount state
  };




  useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []);



  const FormSubmission = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Form submission button clicked'
    });
  };

  // Define state variables for form inputs and result display

  const [result, showResult] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState("");
  const [selectedBedroom, setSelectedBedroom] = useState("1");
  const [selectedBathroom, setSelectedBathroom] = useState("1");
  const [selectedKitchen, setSelectedKitchen] = useState("1");
  const [selectedLaundry, setSelectedLaundry] = useState("1");
  
  const [selectedCleanliness, setSelectedCleanliness] = useState("needs_general_clean");
  const [selectedSteamClean, setSelectedSteamClean] = useState("");
  const [selectedWindowClean, setSelectedWindowClean] = useState("");
  const [selectedBalconyClean, setSelectedBalconyClean] = useState("");

  const form = useRef(null);

   // Function to send an email and display a confirmation message

  const sendEmail = (e) => {
    e.preventDefault();

    const selectedExtras = {
          selectedSteamClean,
          selectedWindowClean,
          selectedBalconyClean,
        };

        // Include the selected extras in the email template
        const emailParams = {
          // Other email parameters...
          selectedExtras: JSON.stringify(selectedExtras),
        };

    
    emailjs
      .sendForm("service_k4aiwmr", "template_rer8is9", form.current, "Fh0Y9YM-DloiKhGif", emailParams)
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    showResult(true);

    navigate("/Thanks");
  };


  // Render the BookingClean component

  return (
    <div>
    <Helmet>
          <meta name="description" content="We offer general and end of lease cleaning service."/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11025934865"></script>
        </Helmet>
      <Header/>
      <div className="end-lease-clean-top">
      <EndOfLeaseCleanTop/>
    
      
      <div className="booking-process">
      <TrippleIcons/>
      </div>
      </div>
      
      <div className="form-clean-book">
      
        
        <h2 className="clean-heading">100% Bond back</h2>
        <p className="clean-heading-p">Based on cleaning</p>
        <p className="clean-heading-p t-plus-c">T&Cs apply</p>
        <h2 className="clean-h2">*select a service, book clean, pay later</h2>

        <form className="form-clean" ref={form} onSubmit={sendEmail}>
          <img className="rating-img-clean" src={rating} alt="rating symbol"/>
          <div className="form-one">
          <p className="price_info"></p>
            <div className="form-container-clean">
            <div className="icon-heading-container">
                    <BsFileEarmarkPerson size={25} />
                    <h4 className="clean-h4">WHO YOU ARE</h4>
                  </div>
           
              <p className="clean-p">This information will be used to contact<br /> you about your service.</p>

              <input className="input-contact-clean" type="text" name="user_name" placeholder="Name" required />
              <input className="input-contact-clean" type="email" name="user_email" placeholder="Email" required />
              <input className="input-contact-clean" type="text" name="user_mobile" placeholder="Mobile" required />

              <div className="address-section">
              <div className="icon-heading-container">
                    <BiLocationPlus size={25} />
                    <h4 className="clean-h4">ADDRESS</h4>
                  </div>
             
                <p className="clean-p">Where would you like us to clean?</p>
                <textarea className="input-contact-clean" type="text" name="address" placeholder="Address" required />
                <textarea className="input-contact-clean" type="text" name="suburb" placeholder="Suburb" required />
              </div>

                <div className="icon-heading-container">
                    <BiHomeSmile size={25} />
                    <h4 className="clean-h4">TELL US ABOUT YOUR HOME</h4>
                  </div>
              <div className="tell-us-service">
                <select className="input-contact-clean input-select-width" name="bedroom" onChange={(e) => setSelectedBedroom(e.target.value)} required>
                  <option value="1" >1 bedroom</option>
                  <option value="2">2 bedrooms</option>
                  <option value="3">3 bedrooms</option>
                  <option value="4">4 bedrooms</option>
                  <option value="5">5 bedrooms</option>
                </select>

                <select className="input-contact-clean input-select-width" name="bathroom" onChange={(e) => setSelectedBathroom(e.target.value)} required>
                
                  <option value="1">1 bathroom</option>
                  <option value="2">2 bathrooms</option>
                  <option value="3">3 bathrooms</option>
                  <option value="4">4 bathrooms</option>
                  <option value="5">5 bathrooms</option>
                </select>

                <select className="input-contact-clean input-select-width" name="kitchen" onChange={(e) => setSelectedKitchen(e.target.value)} required>
                 
                  <option value="1">1 kitchen</option>
                  <option value="2">2 kitchens</option>
                  <option value="3">3 kitchens</option>
                </select>

                <select className="input-contact-clean input-select-width" name="Laundry" onChange={(e) => setSelectedLaundry(e.target.value)} required>
                 
                  <option value="1">1 living & laundry</option>
                  
                </select>

                <div className="icon-heading-container">
                    <MdOutlineCleaningServices size={25} />
                    <h4 className="clean-h4">CONDITION OF BATHROOMS AND<br/> KITCHEN</h4>
                  </div>
               
                  

                <select className="input-contact-clean input-select-width" name="cleanliness" onChange={(e) => setSelectedCleanliness(e.target.value)} required>
                  <option value="needs_general_clean">Needs general clean</option>
                  <option value="medium_deep_clean">Medium deep clean - Slight grease, dirt and soap scum build up</option>
                  <option value="heavy_deep_clean">Heavy deep clean - Heavy grease, dirt and soap scum build up</option>
                </select>

                 <div className="icon-heading-container">
                    <AiOutlinePlusCircle size={25} />
                    <h4 className="clean-h4">ADD EXTRAS</h4>
                  </div>

                <select className="input-contact-clean input-select-width" name="steam_clean" onChange={(e) => setSelectedSteamClean(e.target.value)} >
                  <option value="">Need steam clean?</option>
                  <option value="1_bedroom">1 Bedroom Steam Clean</option>
                  <option value="2_bedroom">2 Bedroom Steam Clean</option>
                  <option value="3_bedroom">3 Bedroom Steam Clean</option>
                  <option value="4_bedroom">4 Bedroom Steam Clean</option>
                  <option value="5_bedroom">5 Bedroom Steam Clean</option>
                  <option value="1_bedroom + living">1 Bedroom + Living Area Steam Clean</option>
                  <option value="2_bedroom + living">2 Bedroom + Living Area Steam Clean</option>
                  <option value="3_bedroom + living">3 Bedroom + Living Area Steam Clean</option>
                  <option value="4_bedroom + living">4 Bedroom + Living Area Steam Clean</option>
                  <option value="5_bedroom + living">5 Bedroom + Living Area Steam Clean</option>
                </select>

                <select
                  className="input-contact-clean input-select-width"
                  name="window_clean" // Change the name attribute to something appropriate
                  onChange={(e) => setSelectedWindowClean(e.target.value)}
                  
                    >
                  <option value="">External window clean?</option>
                  <option value="two_windows">Two Standard windows</option>
                  <option value="four_windows">Standard balcony windows upto four pannels </option>
                  <option value="six_plus_windows">Six to eight Standard windows</option>
                </select>

                <select
                  className="input-contact-clean input-select-width"
                  name="window_clean" // Change the name attribute to something appropriate
                  onChange={(e) => setSelectedBalconyClean(e.target.value)}
              
                    >
                  <option value="">Balcony clean?</option>
                  <option value="small_balcony">Small Balcony - Mop,sweep,cobwebs and dust</option>
                  <option value="medium_balcony">Medium Balcony - Mop,sweep,cobwebs and dust</option>
                  <option value="large_balcony">Large Balcony - Mop,sweep,cobwebs and dust</option>
                </select>




              </div>

              <div className="book-clean">
                <h4 className="clean-h4">WHEN WOULD YOU LIKE US TO COME?</h4>
                <p className="clean-p">Choose the date and arrival window*<br /> that works for you. <br />Last minute booking call on <a href="tel:0433310964" className="number">0433310964</a></p>
                <input
                  className="input-contact-clean"
                  type="datetime-local"
                  name="clean_date"
                  onChange={(e) => setSelectedDateTime(e.target.value)}
                  required
                />
              </div>
              <h4 className="clean-h4">Comments & Special Instructions</h4>
              <textarea className="input-contact-clean" type="text" name="Instructions" placeholder="Is there anything we should know? "  />
              
            </div>
          </div>
          
    
     
          <PriceSection
            selectedBedroom={parseInt(selectedBedroom)}
            selectedBathroom={parseInt(selectedBathroom)}
            selectedKitchen={parseInt(selectedKitchen)}
            selectedLaundry={parseInt(selectedLaundry)}

            selectedCleanliness={selectedCleanliness}
            selectedSteamClean={selectedSteamClean} 
            selectedWindowClean={selectedWindowClean}
            selectedBalconyClean={selectedBalconyClean}
            isDiscountApplied={isDiscountApplied}
            handleDiscountClick={handleDiscountClick}
            setFinalPrice={setFinalPrice}
          />
         
          <div> {result ? <Result /> : null}</div>
          
          <input onClick={FormSubmission} className="qoute-button" type="submit" value="Send booking request" />
          {/*Hidden imput for emailjs*/}
            <input type="hidden" name="selectedSteamClean" value={selectedSteamClean} />
            <input type="hidden" name="selectedWindowClean" value={selectedWindowClean} />
            <input type="hidden" name="selectedBalconyClean" value={selectedBalconyClean} />
           <input type="hidden" name="final_price" value={finalPrice} />

           <div className="review-bottom">
            <div className="review-content-bottom">
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <IoMdStar size={30} color="#ffcd00" />
            <img className="gimage" src={icon} alt="logo for customer rating"/>
           </div>

           </div>
        </form>
        <div className="margin-profile"></div>

     <Profile/>
      </div>  
      <div className="foot"><Footer/></div> 
    </div>
  );
};

export default BookingClean;







