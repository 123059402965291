import React from 'react'
import carpetRoom from "../assets/whyUsPic.png"
import "./SteamCleanTop.css";
import logoCraig from "../assets/craigLogo.png"

const GeneralCleanTwo = () => {
  return (
    
    <div className='carpet-wrapper carpet-wrapper-two'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Carpet that has been steamed clean"/>
        </div> 
        <div className='carpet-description'>
    <h2 className='not-sure rec-head'>Not sure how to keep your home pristine? Wondering what general house cleaning involves?.</h2>
    <p className='p-description'>General house cleaning, is a comprehensive clean leaving your home free from germs and better yet declutters the mind. At Sprucely Clean, we specialize in general house cleaning, ensuring your home is left fresh after every general clean.</p>
    <p className='p-description'>Our professional cleaners in Melbourne are dedicated to delivering exceptional house cleaning services tailored to your needs. With a commitment to excellence, our fully insured and trained teams ensure every corner of your home is spotless.</p>
    <p className='p-description'>Moving out of a rental property can be daunting, especially when it comes to the final clean. But worry not, Sprucely Clean rises to the challenge. Our expert cleaners meticulously attend to every detail, using advanced equipment and premium products to achieve outstanding results. From walls to carpets, windows to bathrooms, we guarantee a pristine finish.</p>
    <p className='p-description'>At Sprucely Clean, we understand that every home is unique, offering bespoke cleaning solutions to fit your requirements and budget. Whether it's a standard clean or a deep clean, we've got you covered.</p>
    <p className='p-description'>No need for a pre-inspection to get a final quote.</p>
    <p className='p-description'>Count on our exceptional customer service and satisfaction guarantee. Don't let cleaning stress you out during your move. Contact Sprucely Clean today, and let us take care of your home while you focus on your transition. You book. We clean. Your home stays sparkling.</p>
    <p className='p-description-partners'>Trusted provider for</p>
    <img className="logo-agent" src={logoCraig} alt="logo of real estate agent"/>
</div>

    </div>
  )
}

export default GeneralCleanTwo