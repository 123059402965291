import React from 'react';
import './EndOfLeaseCleaningPrices.css'; // Assuming you have a CSS file for styling

const GeneralCleaningPrices = () => {
  return (
    <div className="container">
      <h2>General Cleaning Melbourne Prices</h2>
      <p>
        When it comes to General cleaning, the expenses can fluctuate based on various factors. Elements such as the dimensions of the property, its state, and the specific cleaning necessities can all impact the total expense. In Melbourne, the cost of General cleaning can differ depending on these factors.
      </p>
      <p>
        It's always advisable to acquire a customized quote to ascertain the precise cost tailored to your specific requirements. At Sprucely Clean, we offer competitive rates for our professional general cleaning services, ensuring a comprehensive, top-notch cleaning that aligns with your expectations and budget.
      </p>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th className='t-heading'>Property</th>
              <th className='t-heading'>Cleaning Only</th>
              <th className='t-heading'>+ Carpet Steam Cleaning</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Studio Apartment</td>
              <td>$110</td>
              <td>$190</td>
            </tr>
            <tr>
              <td>1 Bedroom</td>
              <td>from $139</td>
              <td>from $219</td>
            </tr>
            <tr>
              <td>2 Bedroom</td>
              <td>from $150</td>
              <td>from $230</td>
            </tr>
            <tr>
              <td>3 Bedroom</td>
              <td>from $193</td>
              <td>from $237</td>
            </tr>
            <tr>
              <td>4 Bedroom</td>
              <td>from $393</td>
              <td>from $473</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GeneralCleaningPrices;
