import HomePage from "./pages/HomePage";
import ReactGA from "react-ga";
import About from "./pages/About";
import News from "./pages/News"
import BlogReno from "./pages/BlogReno"
import BlogCost from  "./pages/BlogCost"
import BlogBond from "./pages/BlogBond"
import BookingPage from "./pages/BookingPage";
import BookingSteam from "./pages/BookingSteam";
import { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BookingClean from "./pages/BookingClean"
import BookingGeneral from "./pages/BookingGeneral";
import GiveGoogleReview from "./components/GiveGoogleReview";
import { Helmet } from "react-helmet"
import ThankYouPage from "./pages/ThankYouPage";
import Licensee from "./pages/Licensee";
import SteamForm from "./pages/SteamForm";
import BookingAirbnb from "./pages/BookingAirbnb";
import GeneralCleaning from "./pages/GeneralCleaning";
import GeneralBookPage from "./pages/GeneralBookPage";
import EndOfLeaseCleanMelb from "./pages/EndOfLeaseCleanMelb";
import GeneralCleaningMelbourne from "./pages/GeneralCleaningMelbourne";
import BookingGeneralQuote from "./pages/BookingGeneralQuote";
import CommercialGyms from "./pages/CommercialGyms";
import BookingGym from "./pages/BookingGym";
import Commercial from "./pages/Commercial";





function App() {

  useEffect(() => {
    // Google Tag for remarketing and conversion tracking
    window.gtag('config', 'AW-11025934865');
  }, []);

useEffect(() => {
  ReactGA.initialize('G-ECHQXBFYLM'); 
  // Report page view
  ReactGA.pageview(window.location.pathname + window.location.search);
}, []);
useEffect(() => {
   console.log(window.location.pathname)
  })



  return (
    <div className="App">
    <Helmet>
          <meta name="description" content="We offer general and end of lease cleaning service."/>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11025934865"></script>
        </Helmet>
     <Router>
     <Routes>
        <Route exact path="/" element={<HomePage/>}/>
        <Route exact path="/about" element={<About/>}/>
        <Route exact path="/News" element={<News/>}/>
        <Route exact path="/BlogReno" element={<BlogReno/>}/>
        <Route exact path="/BlogCost" element={<BlogCost/>}/>
        <Route exact path="/BlogBond" element={<BlogBond/>}/>
        <Route exact path="/end-of-lease-cleaning" element={<BookingClean/>}/>
        <Route exact path="/BookingGeneral" element={<BookingGeneral/>}/>
        <Route exact path="/book-a-walk-through" element={<BookingGym/>}/>
        <Route exact path="/BookingPage" element={<BookingPage/>}/>
        <Route exact path="/BookingSteam" element={<BookingSteam/>}/>
        <Route exact path="/GiveGoogleReview" element={<GiveGoogleReview/>}/>
        <Route exact path="/Thanks" element={<ThankYouPage/>}/>
        <Route exact path="/Licensee" element={<Licensee/>}/>
        <Route exact path="/SteamForm" element={<SteamForm/>}/>
        <Route exact path="/Turn-over-clean" element={<BookingAirbnb/>}/>
        <Route exact path="/GeneralCleaning" element={<GeneralCleaning/>}/>
        <Route exact path="/GeneralBookPage" element={<GeneralBookPage/>}/>
        <Route exact path="/end-of-lease-cleaning-melbourne" element={<EndOfLeaseCleanMelb/>}/>
        <Route exact path="/General-Cleaning-Melbourne" element={<GeneralCleaningMelbourne/>}/>
        <Route exact path="/General-Cleaning-Melbourne-quote" element={<BookingGeneralQuote/>}/>
        <Route exact path="/Commercial-ceaning-gyms" element={<CommercialGyms/>}/>
        <Route exact path="/Commercial-ceaning" element={<Commercial/>}/>
        

        

        


        

        
         
      

     

        

        
      
        
  
      </Routes>
 </Router> 
    </div>
  );
}

export default App;
