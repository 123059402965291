import React from 'react'
import carpetRoom from "../assets/gympic.png"
import rating from "../assets/ratingPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css"
import { Link } from 'react-router-dom';

const GymTop = () => {
  return (
    <div className='carpet-wrapper'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Nice looking styled room with carpet"/>
        </div> 
        <div className='carpet-description'>
         <h6>Fill in your details below for an instant quote, our quotes are fixed price with no hidden additional hourly rates.
         We offer commercial gym cleaning services across Melbourne's northern suburbs and CBD.. </h6>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Commercial gym cleaning service</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Weekly inspections to guarantee quality</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Our founders have owned and operated health clubs</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Increase you 5 star google reviews</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Once off deep gym deep cleaning</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Ongoing maintenance cleaning</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>We take on the operational headache of scheduling</p>
            <p className='p-description '><AiFillCheckCircle size={30} color='#3AAFA9'/>Flexible month to month contracts</p>
            
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/book-a-walk-through"><button className='p-description end-lease-link mar-p-gym'>GET A QUOTE</button></Link>
            
        <img className="rating-img" src={rating} alt="rating symbol"/>
        </div>
        
    </div>
  )
}

export default GymTop