import React, { useEffect } from 'react'
import "./BlogPages.css"
import Header from '../components/Header'
import Footer from "../components/Footer"
import { AiOutlineMinus } from 'react-icons/ai';
import JumpingButton from '../components/JumpingButton';
import ReactGA from "react-ga";


function BlogReno() {
  
 useEffect(() => {
  ReactGA.initialize('G-ECHQXBFYLM'); 
  // Report page view
  ReactGA.pageview(window.location.pathname + window.location.search);
}, []);
useEffect(() => {
   console.log(window.location.pathname)
  })


  return (
    <div className="blog-main-wrapper">
    <Header/>
    <div className="blog-container-pages">
    <div className="heading-content">
  <div className="heading-overlay heading-overlay-blog">
    <h1 className="heading">Australias best home <span className="color__change"><br/>experts.</span><br/><AiOutlineMinus /></h1>
    <div className="jump-container">
      <JumpingButton/>
    </div>
  </div>  
  </div>

   <div className="flex-blog-section">
      <h2 className="blog-pages-heading">Cleaning Your Home: <span className="blog-pages-heading-change">Tips on cleaning chemical free</span></h2>

      <h3 className="blog-pages-h3"><span className="color-change-steps">Tip 1:</span> Use vinegar</h3>
      <p className="blog-pages-p">Use Vinegar and Water to Clean Countertops: Mix equal parts of vinegar and water in a spray bottle, and use this solution to wipe down your countertops. This will not only clean the surface but also disinfect it.</p>

      <h3 className="blog-pages-h3"><span className="color-change-steps">Tip 2:</span> Bakeing soda for your oven</h3>
      <p className="blog-pages-p">Cleaning with Baking Soda and Water: Combine baking soda and water to make an oven cleaning paste is a great way to cut through the grease, you will be amazed with the results. Its as easy as combining water and baking soda until you get a thick past, then apply let sit for 10mins, then remove.</p>

      <h3 className="blog-pages-h3"><span className="color-change-steps">Tip 3:</span> Lemmon for stainless steel</h3>
      <p className="blog-pages-p">Cutting a lemon in half and using the juice to clean your stainless steel equipment will help. Lemon juice's acidity will aid in the dissolution of any surface filth or grime.</p>

      <h3 className="blog-pages-h3"><span className="color-change-steps">Tip 4:</span> Use essential oils </h3>
      <p className="blog-pages-p">Use essential oils to create an all-purpose cleaner: To create an all-purpose cleaner, combine water, vinegar, and a few drops of your prefered essential oil in a spray bottle. Most kitchen surfaces may be cleaned with this.</p>

      <h3 className="blog-pages-h3"><span className="color-change-steps">Tip 5:</span> Clean Your Microwave with Vinegar</h3>
      <p className="blog-pages-p">Clean Your Microwave with Vinegar and Water: Mix equal parts of vinegar and water in a bowl and microwave it for a few minutes. The steam from the solution will help to loosen any grime or dirt in the microwave, making it easier to clean.</p>

      <h3 className="blog-pages-h3"><span className="color-change-steps">Tip 6:</span> Natural Dish Soap</h3>
      <p className="blog-pages-p">Create Your Own Natural Dish Soap by combining liquid Castile soap, water, and a few drops of essential oil in a squeeze container. You may use this to clean cutlery, glasses, and dishes.</p>

     
    </div>
    </div>
    <Footer/>

   </div> 
  );
}

export default BlogReno;