import React from 'react'
import carpetRoom from "../assets/CarpetRoom.png"
import rating from "../assets/ratingPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css"
import { Link } from 'react-router-dom';

const SteamCleanTop = () => {
  return (
    <div className='carpet-wrapper'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Nice looking styled room with carpet"/>
        </div> 
        <div className='carpet-description'>
         <h6>Fill in your details below for an instant quote, our quotes are fixed price with no hidden addtional hourly rates.
         We offer a premium carpet and furniture steam clean service across Melbournes northen suburbs. </h6>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Carpet steam cleaning service</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>10% off first clean, fast booking and instant quote</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Sofa steam cleaning service</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Rug steam cleaning service</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>End of lease and general carpet steam clean</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>5 star customer rating</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Local carpet cleaning services</p>
            <p className='p-description custom-p'><span className='finger'>👇</span> Build your custom quote here <span className='finger'>👇</span></p>
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/SteamForm"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            
        <img className="rating-img" src={rating} alt="rating symbol"/>
        </div>
        
    </div>
  )
}

export default SteamCleanTop