import React from 'react'
import carpetRoom from "../assets/CarpetRoom.png"
import rating from "../assets/ratingPic.png"
import { AiFillCheckCircle } from 'react-icons/ai';
import "./SteamCleanTop.css"
import { Link } from 'react-router-dom';

const Airbnbtop = () => {
  return (
    <div className='carpet-wrapper'>
        <div className='image-carpet'>
            <img className="carpet-room" src={carpetRoom} alt="Nice looking styled room with carpet"/>
        </div> 
        <div className='carpet-description'>
         <h6>Fill in your details below for an instant quote, our quotes are fixed price with no hidden additional hourly rates.
         We offer Airbnb cleaning services across Melbourne's northern suburbs and CBD.. </h6>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Airbnb cleaning service</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>20% off first clean, fast booking and instant quote</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Fast turn over airbnb cleaning service</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>Boost your rating to 5 stars based on cleaning</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>No risk of bad reviews due to cleaning</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>5 star customer rating</p>
            <p className='p-description'><AiFillCheckCircle size={30} color='#3AAFA9'/>We take on the operational headache</p>
            <p className='p-description custom-p'><span className='finger'>👇</span> Custom quote here + 20% dicount <span className='finger'>👇</span></p>
            <Link style={{textDecoration: 'none', color: 'inherit', }}  to="/BookingGeneral"><button className='p-description end-lease-link'>LIVE BOOKING AND PRICE</button></Link>
            
        <img className="rating-img" src={rating} alt="rating symbol"/>
        </div>
        
    </div>
  )
}

export default Airbnbtop